import React, { useState } from 'react';


const OptionsForm = ({ asset, strategy, timeframeOptions, handleUpdateConfig }) => {
  const [basePosition, setBasePosition] = useState(asset.base_quantity);
  const [step, setStep] = useState(asset.step_quantity);
  const [timeframe, setTimeframe] = useState(strategy === 'gyrox-v1' ? asset.active_timeframe_v1 : asset.active_timeframe_v2);

  console.log(asset);
  
  return (
    <div className="max-w-sm mx-auto bg-white shadow-md rounded-lg p-6">
      <form>
        <div className="mb-4">
          <label htmlFor="basePosition" className="block text-sm font-medium text-gray-700">
            Base Position
          </label>
          <input
            type="number"
            id="basePosition"
            value={basePosition}
            onChange={(e) => setBasePosition(e.target.value)}
            className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md text-sm shadow-sm focus:ring-blue-500 focus:border-blue-500"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="step" className="block text-sm font-medium text-gray-700">
            Step
          </label>
          <input
            type="number"
            id="step"
            value={step}
            onChange={(e) => setStep(e.target.value)}
            className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md text-sm shadow-sm focus:ring-blue-500 focus:border-blue-500"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="timeframe" className="block text-sm font-medium text-gray-700">
            Timeframe
          </label>
          <select
            id="timeframe"
            value={timeframe}
            onChange={(e) => setTimeframe(e.target.value)}
            className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md text-sm shadow-sm focus:ring-blue-500 focus:border-blue-500"
          >
            {timeframeOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
        <button
          type="submit"
          className="w-full px-4 py-2 bg-blue-500 text-white rounded-md text-sm font-medium hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          onClick={(e) => {
            e.preventDefault();
            handleUpdateConfig(asset.symbol, asset.broker, basePosition, step, timeframe, asset.account, asset.sectype);
          }}
        >
          Confirm
        </button>
      </form>
    </div>
  );
};

export default OptionsForm;
