import React, { useState, useEffect } from 'react';
import Tile from '../components/common/Tile';
import AccountList from '../components/Dashboard/AccountList';
import { fetchDeepAssets, fetchAccounts, switchMaster, toggleActive, fetchMasterStatus, toggleMaster, updateBlacklistStatus } from '../services/api';

function ConfigPage() {
  const [accounts, setAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [assets, setAssets] = useState([]);
  const [activeMenu, setActiveMenu] = useState(null);
  const [masterSwitch, setMasterSwitch] = useState(null);
  const [isSelectMode, setIsSelectMode] = useState(false);
  const [selectedAssets, setSelectedAssets] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const loadAccounts = async () => {
      try {
        const fetchedAccounts = await fetchAccounts();
        setAccounts(fetchedAccounts);
      } catch (error) {
        console.error('Ошибка при загрузке аккаунтов:', error);
      }
    };
    loadAccounts();
  }, []);

  useEffect(() => {
    if (selectedAccount) {
      const loadData = async () => {
        setIsLoading(true);
        try {
          const [assetsData, masterStatus] = await Promise.all([
            fetchDeepAssets(selectedAccount.broker, selectedAccount.account),
            fetchMasterStatus(selectedAccount.broker)
          ]);
          setAssets(assetsData);
          setMasterSwitch(masterStatus.status);
        } catch (error) {
          console.error('Ошибка загрузки данных:', error);
          alert('Не удалось получить данные от брокера');
        } finally {
          setIsLoading(false);
        }
      };
      loadData();
    }
  }, [selectedAccount]);

  const handleAccountSelect = (account) => {
    setSelectedAccount(account);
    setActiveMenu(null);
  };

  const handleToggleMenu = (menuName) => {
    setActiveMenu(activeMenu === menuName ? null : menuName);
    setIsSelectMode(false);
    setSelectedAssets([]);
  };

  const handleToggleActiveAssetClick = async (asset) => {
    try {
      const newStatus = await toggleActive(
        asset.symbol || asset.epic,
        asset.broker,
        asset.account || null,
        asset.sectype || ''
      );
      if (newStatus !== null) {
        setAssets(prev => prev.map(a => 
          a.symbol === asset.symbol && a.broker === asset.broker
            ? { ...a, isAssetActive: newStatus }
            : a
        ));
      }
    } catch (error) {
      console.error('Ошибка при переключении активности:', error);
    }
  };

  const handleToggleAlgoAssetClick = async (asset) => {
    try {
      const res = await toggleMaster(
        asset.symbol || asset.epic,
        asset.broker,
        asset.account || null,
        asset.sectype || ''
      );
      if (res !== null) {
        setAssets(prev => prev.map(a => 
          a.symbol === asset.symbol && a.broker === asset.broker
            ? { ...a, isAlgoActive: res }
            : a
        ));
      }
    } catch (error) {
      console.error('Ошибка при переключении алгоритма:', error);
    }
  };

  const getAssetKey = (asset) => {
    return [
      asset.broker,
      asset.account || 'no-account',
      asset.symbol,
      asset.sectype || 'no-sectype',
      asset.exchange || 'no-exchange'
    ].join('|');
  };

  const getDisplayName = (asset) => {
    let name = '';
    if (asset.broker === 'ib') {
      name = `${asset.account} - ${asset.symbol}`;
    } else if (asset.broker === 'ig') {
      name = asset.name;
    } else {
      name = asset.symbol;
    }
    return name + (asset.sectype ? ` (${asset.sectype})` : '');
  };

  const addToBlacklist = async (assetsToAdd) => {
    try {
      const responses = await Promise.all(
        assetsToAdd.map(asset =>
          updateBlacklistStatus(asset.broker, asset.account, asset.symbol, asset.sectype, true)
        )
      );
      console.log('Responses from updateBlacklistStatus:', responses); // Проверка ответа API
      setAssets(prev =>
        prev.map(asset =>
          assetsToAdd.some(a => getAssetKey(a) === getAssetKey(asset))
            ? { ...asset, isBlackList: true, isAssetActive: true }
            : asset
        )
      );
      setSelectedAssets([]);
      setIsSelectMode(false);
    } catch (error) {
      console.error('Ошибка при добавлении в blacklist:', error);
    }
  };

  const removeFromBlacklist = async (assetsToRemove) => {
    try {
      const responses = await Promise.all(
        assetsToRemove.map(asset =>
          updateBlacklistStatus(asset.broker, asset.account, asset.symbol, asset.sectype, false)
        )
      );
      console.log('Responses from updateBlacklistStatus:', responses); // Проверка ответа API
      setAssets(prev =>
        prev.map(asset =>
          assetsToRemove.some(a => getAssetKey(a) === getAssetKey(asset))
            ? { ...asset, isBlackList: false, isAssetActive: false }
            : asset
        )
      );
      setSelectedAssets([]);
      setIsSelectMode(false);
    } catch (error) {
      console.error('Ошибка при удалении из blacklist:', error);
    }
  };

  const handleMasterSwitchClick = async (broker) => {
    try {
      const newStatus = await switchMaster(broker);
      setMasterSwitch(newStatus);
    } catch (error) {
      console.error('Ошибка при переключении мастера:', error);
    }
  };

  return (
    <div className="space-y-8">
      <h1 className="text-3xl font-bold">Config Page</h1>

      <div>
        <h2 className="text-xl font-semibold">Choose broker</h2>
        <AccountList accounts={accounts} onSelect={handleAccountSelect} />
      </div>

      {selectedAccount && (
        <div>
          <h2 className="text-xl font-semibold">Actions</h2>
          <div className="flex justify-center space-x-4">
            <Tile onClick={() => handleToggleMenu('algo')}>
              <div className="p-4 text-center cursor-pointer">Toggle algo</div>
            </Tile>
            <Tile onClick={() => handleToggleMenu('active')}>
              <div className="p-4 text-center cursor-pointer">Toggle Active</div>
            </Tile>
            <Tile onClick={() => handleToggleMenu('blacklist')}>
              <div className="p-4 text-center cursor-pointer">Manage Blacklist</div>
            </Tile>
          </div>

          <div className="flex justify-center mt-4">
            <div className="w-2/3">
              {isLoading ? (
                <div className="text-center p-4">
                  <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500 mx-auto"></div>
                  <p className="mt-2">Loading data from broker...</p>
                </div>
              ) : (
                <>
                  {activeMenu === 'algo' && (
                    <div className="mt-2 text-center">
                      <div className="flex justify-between items-center mb-4">
                        <h3 className="text-lg font-semibold">Choose symbol</h3>
                        {!isSelectMode ? (
                          <button
                            onClick={() => setIsSelectMode(true)}
                            className="px-4 py-2 bg-blue-500 text-white rounded"
                          >
                            Select
                          </button>
                        ) : (
                          <div className="space-x-2">
                            <button
                              onClick={() => addToBlacklist(selectedAssets)}
                              className="px-4 py-2 bg-red-500 text-white rounded"
                            >
                              Hide Selected
                            </button>
                            <button
                              onClick={() => {
                                setSelectedAssets(assets.filter(asset => !asset.isBlackList));
                              }}
                              className="px-4 py-2 bg-purple-500 text-white rounded"
                            >
                              Select All
                            </button>
                            <button
                              onClick={() => {
                                setIsSelectMode(false);
                                setSelectedAssets([]);
                              }}
                              className="px-4 py-2 bg-gray-500 text-white rounded"
                            >
                              Cancel
                            </button>
                          </div>
                        )}
                      </div>
                      <div className="grid grid-cols-2 gap-4">
                        {assets
                          .filter(asset => !asset.isBlackList)
                          .map(asset => {
                            const displayName = getDisplayName(asset) + 
                              (asset.isAlgoActive ? ' - TRADE' : ' - FADE');
                            return (
                              <Tile
                                key={getAssetKey(asset)}
                                onClick={() => {
                                  if (isSelectMode) {
                                    setSelectedAssets(prev =>
                                      prev.some(selected => getAssetKey(selected) === getAssetKey(asset))
                                        ? prev.filter(selected => getAssetKey(selected) !== getAssetKey(asset))
                                        : [...prev, asset]
                                    );
                                  } else {
                                    handleToggleAlgoAssetClick(asset);
                                  }
                                }}
                                className={`p-4 text-center ${
                                  selectedAssets.some(selected => getAssetKey(selected) === getAssetKey(asset))
                                    ? 'bg-blue-200'
                                    : 'bg-white'
                                } cursor-pointer`}
                              >
                                {isSelectMode && (
                                  <input
                                    type="checkbox"
                                    checked={selectedAssets.some(selected => 
                                      getAssetKey(selected) === getAssetKey(asset)
                                    )}
                                    className="mr-2"
                                    readOnly
                                  />
                                )}
                                <div>{displayName}</div>
                              </Tile>
                            );
                          })}
                      </div>
                    </div>
                  )}

                  {activeMenu === 'active' && (
                    <div className="mt-2 text-center">
                      <div className="flex justify-between items-center mb-4">
                        <h3 className="text-lg font-semibold">Choose symbol</h3>
                        {!isSelectMode ? (
                          <button
                            onClick={() => setIsSelectMode(true)}
                            className="px-4 py-2 bg-blue-500 text-white rounded"
                          >
                            Select
                          </button>
                        ) : (
                          <div className="space-x-2">
                            <button
                              onClick={() => addToBlacklist(selectedAssets)}
                              className="px-4 py-2 bg-red-500 text-white rounded"
                            >
                              Hide Selected
                            </button>
                            <button
                              onClick={() => {
                                setSelectedAssets(assets.filter(asset => !asset.isBlackList));
                              }}
                              className="px-4 py-2 bg-purple-500 text-white rounded"
                            >
                              Select All
                            </button>
                            <button
                              onClick={() => {
                                setIsSelectMode(false);
                                setSelectedAssets([]);
                              }}
                              className="px-4 py-2 bg-gray-500 text-white rounded"
                            >
                              Cancel
                            </button>
                          </div>
                        )}
                      </div>
                      <div className="grid grid-cols-2 gap-4">
                        {assets
                          .filter(asset => !asset.isBlackList)
                          .map(asset => {
                            const displayName = getDisplayName(asset) + 
                              (asset.isAssetActive ? ' - ✅' : ' - ❌');
                            return (
                              <Tile
                                key={getAssetKey(asset)}
                                onClick={() => {
                                  if (isSelectMode) {
                                    setSelectedAssets(prev =>
                                      prev.some(selected => getAssetKey(selected) === getAssetKey(asset))
                                        ? prev.filter(selected => getAssetKey(selected) !== getAssetKey(asset))
                                        : [...prev, asset]
                                    );
                                  } else {
                                    handleToggleActiveAssetClick(asset);
                                  }
                                }}
                                className={`p-4 text-center ${
                                  selectedAssets.some(selected => getAssetKey(selected) === getAssetKey(asset))
                                    ? 'bg-blue-200'
                                    : 'bg-white'
                                } cursor-pointer`}
                              >
                                {isSelectMode && (
                                  <input
                                    type="checkbox"
                                    checked={selectedAssets.some(selected => 
                                      getAssetKey(selected) === getAssetKey(asset)
                                    )}
                                    className="mr-2"
                                    readOnly
                                  />
                                )}
                                <div>{displayName}</div>
                              </Tile>
                            );
                          })}
                      </div>
                    </div>
                  )}

                  {activeMenu === 'blacklist' && (
                    <div className="mt-2 text-center">
                      <div className="flex justify-between items-center mb-4">
                        <h3 className="text-lg font-semibold">Blacklisted Symbols</h3>
                        <div className="space-x-2">
                          {!isSelectMode ? (
                            <button
                              onClick={() => setIsSelectMode(true)}
                              className="px-4 py-2 bg-blue-500 text-white rounded"
                            >
                              Select
                            </button>
                          ) : (
                            <>
                              <button
                                onClick={() => removeFromBlacklist(selectedAssets)}
                                className="px-4 py-2 bg-green-500 text-white rounded"
                              >
                                Restore Selected
                              </button>
                              <button
                                onClick={() => {
                                  setSelectedAssets(assets.filter(asset => asset.isBlackList));
                                }}
                                className="px-4 py-2 bg-purple-500 text-white rounded"
                              >
                                Select All
                              </button>
                              <button
                                onClick={() => {
                                  setIsSelectMode(false);
                                  setSelectedAssets([]);
                                }}
                                className="px-4 py-2 bg-gray-500 text-white rounded"
                              >
                                Cancel
                              </button>
                            </>
                          )}
                        </div>
                      </div>
                      <div className="grid grid-cols-2 gap-4">
                        {assets
                          .filter(asset => asset.isBlackList)
                          .map(asset => (
                            <Tile
                              key={getAssetKey(asset)}
                              onClick={() => {
                                if (isSelectMode) {
                                  setSelectedAssets(prev =>
                                    prev.some(selected => getAssetKey(selected) === getAssetKey(asset))
                                      ? prev.filter(selected => getAssetKey(selected) !== getAssetKey(asset))
                                      : [...prev, asset]
                                  );
                                }
                              }}
                              className={`p-4 text-center ${
                                selectedAssets.some(selected => getAssetKey(selected) === getAssetKey(asset)) 
                                  ? 'bg-blue-200' 
                                  : 'bg-white'
                              }`}
                            >
                              {isSelectMode && (
                                <input
                                  type="checkbox"
                                  checked={selectedAssets.some(selected => getAssetKey(selected) === getAssetKey(asset))}
                                  className="mr-2"
                                  readOnly
                                />
                              )}
                              <div>{getDisplayName(asset)}</div>
                            </Tile>
                          ))}
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>

          {(selectedAccount.broker === 'okx' || selectedAccount.broker === 'ib') && masterSwitch !== null && (
            <div className="mt-4">
              <h3 className="text-lg font-semibold text-center">Master Switch</h3>
              <div className="flex justify-center space-x-4 mt-2">                
                  <Tile onClick={() => handleMasterSwitchClick(selectedAccount.broker)}>
                    <div className="p-4 text-center cursor-pointer">
                      {selectedAccount.broker.toUpperCase()} Master Switch: {masterSwitch ? 'ON' : 'OFF'}
                    </div>
                  </Tile>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default ConfigPage;