import React, { useState } from 'react'

function ToggleSwitch({ onToggle, options, defaultOption }) {
  const [activeOption, setActiveOption] = useState(defaultOption || 'middle');

  const handleToggleClick = (option, index) => {
    setActiveOption(option);
    onToggle(index);
  };

  return (
    <div className="bg-gray-200 text-sm text-gray-500 leading-none border-2 border-gray-200 rounded-full inline-flex">
      <button
        className={`inline-flex items-center transition-colors duration-300 hover:text-blue-400 rounded-full px-4 py-2 ${
          activeOption === 'left' ? 'bg-white text-blue-400 shadow-md' : ''
        }`}
        onClick={() => handleToggleClick('left', 0)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="fill-current w-4 h-4 mr-2"
        >
          <line x1="8" y1="6" x2="21" y2="6" />
          <line x1="8" y1="12" x2="21" y2="12" />
          <line x1="8" y1="18" x2="21" y2="18" />
          <line x1="3" y1="6" x2="3.01" y2="6" />
          <line x1="3" y1="12" x2="3.01" y2="12" />
          <line x1="3" y1="18" x2="3.01" y2="18" />
        </svg>
        <span>{options[0]}</span> 
      </button>
      <button
        className={`inline-flex items-center transition-colors duration-300 hover:text-blue-400 rounded-full px-4 py-2 ${
          activeOption === 'middle' ? 'bg-white text-blue-400 shadow-md' : ''
        }`}
        onClick={() => handleToggleClick('middle', 1)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="fill-current w-4 h-4 mr-2"
        >
          <rect x="3" y="3" width="7" height="7" />
          <rect x="14" y="3" width="7" height="7" />
          <rect x="14" y="14" width="7" height="7" />
          <rect x="3" y="14" width="7" height="7" />
        </svg>
        <span>{options[1]}</span> 
      </button>
      <button
        className={`inline-flex items-center transition-colors duration-300 hover:text-blue-400 rounded-full px-4 py-2 ${
          activeOption === 'right' ? 'bg-white text-blue-400 shadow-md' : ''
        }`}
        onClick={() => handleToggleClick('right', 2)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="fill-current w-4 h-4 mr-2"
        >
          <line x1="12" y1="12" x2="12" y2="6" /> 
          <line x1="12" y1="12" x2="18" y2="15" /> 
          <line x1="12" y1="12" x2="6" y2="15" /> 
        </svg>
        <span>{options[2]}</span> 
      </button>
    </div>
  );
};

export default ToggleSwitch;