import React from 'react';

import { sortObjectsByDateDesc } from '../../utils/helpers';

function StatisticsTable({ data }) {
    if (!data || data.length === 0) return <p>Loading..</p>;

    return (
        <div className="p-0 h-64 overflow-y-auto">
            <table className="min-w-full bg-white border border-gray-200 rounded-lg shadow-md">
                <thead className='sticky top-0 border-b bg-gray-100 border-gray-200'>
                <tr className="border bg-gray-100 border-gray-200">
                    {Object.keys(data[0]).map((key) => (
                    <th
                        key={key}
                        className="py-3 px-4 text-left text-gray-600 font-medium uppercase tracking-wider"
                    >
                        {key}
                    </th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {sortObjectsByDateDesc(data).map((item, index) => (
                    <tr
                    key={index}
                    className={index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}
                    >
                    {Object.values(item).map((value, idx) => (
                        <td
                        key={idx}
                        className="py-2 px-4 border-b border-gray-200 text-gray-700"
                        >
                        {value}
                        </td>
                    ))}
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default StatisticsTable;