import React from 'react'
import Tile from '../common/Tile'

const ConfigPanel = ({
    selectedAsset,
    masterSwitch,
    handleToggleAlgoAssetClick,
    handleToggleFestivalAssetClick,
    handleToggleActiveAssetClick,
    handleToggleOffensiveAssetClick,
    handleMasterSwitchClick,
    gridLocation
}) => {

    if (gridLocation === undefined) {
        gridLocation = 'sm:col-start-3 sm:col-end-3 sm:row-start-1 col-start-1 col-end-1 row-start-2 row-end-2';
    }
  return (
    <div className={`${gridLocation}`}>
        <div className="space-y-2 ml-4">
            <Tile onClick={handleToggleAlgoAssetClick}>
                <div className="p-4 bg-white text-center cursor-pointer">{ selectedAsset.isAlgoActive ? "TRADE" : "FADE" }</div>
            </Tile>
            <Tile onClick={handleToggleFestivalAssetClick}>
                <div className="p-4 bg-white text-center cursor-pointer">{ selectedAsset.isFestival ? "FESTIVAL" : "NO FESTIVAL" }</div>
            </Tile>
            <Tile onClick={handleToggleActiveAssetClick}>
                <div className="p-4 bg-white text-center cursor-pointer">{ selectedAsset.isAssetActive ? "ACTIVE" : "INACTIVE" }</div>
            </Tile>
            <Tile onClick={handleToggleOffensiveAssetClick}>
                <div className="p-4 bg-white text-center cursor-pointer">{ selectedAsset.isOffensive ? "OFFENSIVE" : "DEFENSIVE" }</div>
            </Tile>
            <Tile onClick={() => handleMasterSwitchClick(selectedAsset.broker)}>
                <div className="p-4 bg-white text-center cursor-pointer">{selectedAsset.broker.toUpperCase()} Master Switch: { masterSwitch ? 'ON' : 'OFF' }</div>
            </Tile>
        </div>
    </div>
  )
}

export default ConfigPanel