import { React, useState, useEffect } from 'react'
import Tile from '../common/Tile'

import { timeframes } from '../../utils/helpers'

const ConfigPanel = ({
    selectedAsset,
    masterSwitch,
    handleToggleAlgoAssetClick,
    handleToggleSpinDownClick,
    handleToggleActiveAssetClick,
    handleSetTimeframeSideClick,
    handleMasterSwitchClick,
    handleSetJoeRider,
    handleSetJ2F,
    gridLocation,
    timeframesInfo,
}) => {
    const [timeframe, setTimeframe] = useState(timeframesInfo.j2f_target);

    useEffect(() => {
        if (
            timeframesInfo.active_timeframe !== timeframe &&
            !timeframesInfo.is_j2f && !timeframesInfo.is_02f
         ) {
            setTimeframe(timeframesInfo.j2f_target);
         }
    }, [timeframesInfo])

    const handleSubmitJ2F = (e) => {
        e.preventDefault();
        if (timeframe !== timeframesInfo.active_timeframe) {
            handleSetJ2F(timeframe, true);
        }
      };
    console.log("Rerender again.")

    if (gridLocation === undefined) {
        gridLocation = 'sm:col-start-3 sm:col-end-3 sm:row-start-1 col-start-1 col-end-1 row-start-2 row-end-2';
    }
  return (
    <div className={`${gridLocation}`}>
        <div className="space-y-2 ml-4">
            <Tile onClick={handleToggleActiveAssetClick}>
                <div className="p-4 bg-white text-center cursor-pointer">{ selectedAsset.isAssetActive ? "ACTIVE" : "INACTIVE" }</div>
            </Tile>
            <div className='grid grid-cols-subgrid cols-2 rows-1 gap-4 h-12'>
                <Tile
                    onClick={() => handleSetTimeframeSideClick('buy', timeframesInfo.active_timeframe)}
                    className={`col-start-1 text-center border-opacity-0 shadow-lg py-2 cursor-pointer bg-green-500 bg-opacity-65`}
                />
                <Tile
                    onClick={() => handleSetTimeframeSideClick('sell', timeframesInfo.active_timeframe)}
                    className={`col-start-2 text-center border-opacity-0 shadow-lg py-2 cursor-pointer bg-red-500 bg-opacity-65`}
                />
            </div>
            <Tile onClick={handleToggleAlgoAssetClick}>
                <div className="p-4 bg-white text-center cursor-pointer">{ timeframesInfo.is_algo_active ? "TRADE" : "FADE" }</div>
            </Tile>
            <Tile onClick={handleToggleSpinDownClick}>
                <div className="p-4 bg-white text-center cursor-pointer">{ timeframesInfo.is_spin_down ? "Shabbat Shalom" : "No Shabbat Shalom" }</div>
            </Tile>
            <Tile onClick={() => handleSetJoeRider(!timeframesInfo.is_joe_rider)}>
                <div className="p-4 bg-white text-center cursor-pointer">{ timeframesInfo.is_joe_rider ? "DJANGO UNCHAINED" : "DJANGO CHAINED" }</div>
            </Tile>
            <Tile onClick={() => timeframesInfo.is_j2f || timeframesInfo.is_02f ? handleSetJ2F(timeframesInfo.j2f_target, false) : {}}
                className={timeframesInfo.is_j2f || timeframesInfo.is_02f ? 'bg-green-300 cursor-pointer hover:bg-opacity-80' : ''}>
                <form className='w-full'>
                    <div className='lg:flex mx-6 md:items-center content-center'>
                        <div className="lg:w-1/3 px-2 mt-4 mb-2 lg:my-4">
                            <label className={`block text-gray-500 font-bold  text-center mb-1 lg:mb-0 pr-4 ${timeframesInfo.is_j2f || timeframesInfo.is_02f ? 'cursor-pointer' : ''}`} for='select-j2f-timeframe'>
                                J2F target
                            </label>
                        </div>
                        <div className='lg:w-1/3 px-2 my-2 lg:my-4'>
                            <select
                                id="timeframe"
                                value={timeframe}
                                onChange={(e) => setTimeframe(e.target.value)}
                                className="block w-full px-4 py-2 bg-white border border-gray-300 rounded-md text-sm shadow-sm focus:ring-blue-500 focus:border-blue-500"
                            >
                                {timeframes.map((option) => (
                                <option key={option} value={option}>
                                    {option}
                                </option>
                                ))}
                            </select>
                        </div>
                        <div className='lg:w-1/3 px-2 mt-2 mb-4 lg:my-4'>
                            <button
                                type="submit"
                                className="w-full px-4 py-2 bg-blue-500 text-white rounded-md text-sm font-medium hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                onClick={handleSubmitJ2F}
                            >
                                Confirm
                            </button>
                        </div>
                    </div>
                </form>
            </Tile>
        </div>
    </div>
  )
}

export default ConfigPanel