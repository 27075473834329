import React, { useState, useEffect } from 'react';
import AccountList from '../components/Dashboard/AccountList';
import AssetList from '../components/Dashboard/AssetList';
import ControlPanel from '../components/Dashboard/ControlPanel';
import StatisticsTable from '../components/Dashboard/StatisticsTable';
import { fetchAccounts, fetchAssets, fetchStatistics, fetchSources } from '../services/api';

function DashboardPage() {
  const [accounts, setAccounts] = useState([]);
  const [assets, setAssets] = useState([]);
  const [statistics, setStatistics] = useState([]);
  const [sources, setSources] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [selectedAsset, setSelectedAsset] = useState(null);
  // const [latestSelection, setLatestSelection] = useState(() => () => {});

  useEffect(() => {
    const loadAccounts = async () => {
      const fetchedAccounts = await fetchAccounts();
      setAccounts(fetchedAccounts);
    };
    loadAccounts();
  }, []);

  useEffect(() => {
    const loadAssets = async () => {
      if (selectedAccount) {
        const fetchedAssets = await fetchAssets(
          selectedAccount.account,
          selectedAccount.broker
        );
        if (Array.isArray(fetchedAssets)) {
          setAssets(fetchedAssets);
        } else {
          setAssets([fetchedAssets])
        }
        console.log(fetchedAssets);
      }
    };
    loadAssets();
  }, [selectedAccount]);

  useEffect(() => {
    const loadStatistics = async () => {
      if (selectedAsset) {
        const fetchedStatistics = await fetchStatistics(
          selectedAccount.broker,
          selectedAccount.account,
          selectedAsset.symbol,
          selectedAsset.sectype
        );
        setStatistics(fetchedStatistics);
      }
    };
    const loadSources = async () => {
      if (selectedAsset) {
        const fetchedSources = await fetchSources(
          selectedAccount.broker,
          selectedAccount.account,
          selectedAsset.symbol,
          selectedAsset.sectype
        );
        setSources(fetchedSources);
      }
    };
    loadSources();
    loadStatistics(); 
  }, [selectedAsset]);

  const handleAccountSelect = (account) => {
    setSelectedAccount(account);
    setSelectedAsset(null);
    // setLatestSelection(setSelectedAccount);
  };

  const handleAssetSelect = (asset) => {
    setSelectedAsset(asset);
    // setLatestSelection(setSelectedAsset);
  };

  // const handleGoBack = () => {
  //   latestSelection(null);
  // };

  return (
    <div className="space-y-8">
      {/* <button onClick={() => handleGoBack()}>
        Back
      </button> */}
      <h1 className="text-3xl font-bold">Dashboard</h1>
      {!selectedAccount && (
        <AccountList accounts={accounts} onSelect={handleAccountSelect} />
      )}
      {selectedAccount && !selectedAsset && (
        <AssetList assets={assets} onSelect={handleAssetSelect} />
      )}
      {selectedAsset && (
        <ControlPanel asset={selectedAsset} />
      ) && (
        <StatisticsTable data={statistics} />
      )}
    </div>
  );
}

export default DashboardPage;