import React from 'react';

function Footer() {
  return (
    <footer className="bg-white border-t-2 border-accent">
      <div className="container mx-auto px-4 py-4 text-center text-gray-600">
        <p>&copy; {new Date().getFullYear()} Mozart Trader. All rights reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;