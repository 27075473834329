import React from 'react';
// import axios from 'axios';
import Tile from '../common/Tile';
import logos from '../common/Logos';

// const logos = {
//   "ib": "https://www.interactivebrokers.com/images/common/logos/ibkr/ibkr-symbol.svg",
//   "ig": "https://upload.wikimedia.org/wikipedia/commons/2/2d/IG_Group_logo.svg",
//   "okx": "https://upload.wikimedia.org/wikipedia/commons/e/e4/OKX_Logo.svg"
// }

function BrokerLinking({ supportedBrokers, linkedBrokers, onLinkBroker }) {
  const handleLinkBroker = (brokerName) => {

    onLinkBroker(brokerName);
    console.log(`Linking broker: ${brokerName}`);
  };

  return (
    <div>
      <h2 className="text-2xl font-bold mb-4 text-gray-800">Link Accounts</h2>
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
        {supportedBrokers.map((broker) => {
          var isLinked;
          if (linkedBrokers != null) {
            isLinked = linkedBrokers.some(
              (linkedBroker) => linkedBroker.id === broker.id
            );
          } else { isLinked = false; }
          return (
            <Tile
              key={broker.id}
              // onClick={() => !isLinked && handleLinkBroker(broker.name)}
              onClick={() => handleLinkBroker(broker.name)}
              className={`flex flex-col items-center p-4 cursor-pointer bg-white`}
            >
              <img
                src={logos[broker.name]}
                alt={`${broker.name} logo`}
                className="w-16 h-16 mb-2"
              />
              <h3 className="font-semibold text-center text-gray-800">{broker.name.toUpperCase()}</h3>
              {isLinked ? (
                <span className="text-accent-dark text-sm">Linked</span>
              ) : (
                <span className="text-accent text-sm">Link</span>
              )}
            </Tile>
          );
        })}
      </div>
    </div>
  );
}

export default BrokerLinking;