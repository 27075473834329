import api, { setAuthToken } from './api';

export const login = async (username, password) => {
  try {
    const authData = new FormData();
    authData.append('username', username); 
    authData.append('password', password);
    const response = await api.post('/auth/login', authData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    if (response.status !== 200) {
      return null;
    }
    const { access_token } = response.data;
    setAuthToken(access_token);
    localStorage.setItem('token', access_token);
    return access_token;
  } catch (error) {
    throw new Error(error.response.data.message || 'Login failed');
  }
};

export const register = async (email, password, username) => {
  try {
    const response = await api.post('/auth/register', { email, password, username });
    if (response.status !== 200) {
      return null;
    }
    const { access_token } = response.data;
    setAuthToken(access_token);
    localStorage.setItem('token', access_token);
    return access_token;
  } catch (error) {
    throw new Error(error.response.data.message || 'Registration failed');
  }
};

export const logout = () => {
  localStorage.removeItem('token');
  setAuthToken(null);
};

export const checkAuth = async () => {
  const token = localStorage.getItem('token');
  if (token) {
    setAuthToken(token);
    try {
      const response = await api.get('/auth/me');
      return response.data;
    } catch (error) {
      logout();
      throw new Error('Authentication failed');
    }
  }
  return null;
};

export const updateApikey = async (username, email) => {
  try {
    const response = await api.post('/auth/update-apikey', { username, email });
    if (response.status === 200 && response.data.status === 'success') {
      return response.data.api_key; 
    }
    return null;
  } catch (error) {
    const errorMessage = error.response?.data?.message || 'Не удалось обновить API ключ';
    throw new Error(errorMessage);
  }
};
