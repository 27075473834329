import React, { useState, useEffect } from 'react';
import Tile from '../components/common/Tile';
import AccountList from '../components/Dashboard/AccountList';
import { fetchDeepAssets, fetchAccounts, fetchAssets, toggleMaster, toggleFestival, toggleOffensive, switchMaster, toggleActive, fetchMasterStatus } from '../services/api';

function ConfigPage() {
  const [accounts, setAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [assets, setAssets] = useState([]);
  const [activeMenu, setActiveMenu] = useState(null); 
  const [masterSwitch, setMasterSwitch] = useState(null);

  useEffect(() => {
    const loadAccounts = async () => {
      try {
        const fetchedAccounts = await fetchAccounts();
        setAccounts(fetchedAccounts);
      } catch (error) {
        console.error('Ошибка при загрузке аккаунтов:', error);
      }
    };
    loadAccounts();
  }, []);

  useEffect(() => {
    if (selectedAccount) {
      if (activeMenu === 'active') {
        const loadDeepAssets = async () => {
          try {
            const fetchedAssets = await fetchDeepAssets(selectedAccount.broker, selectedAccount.account);
            setAssets(fetchedAssets);
          } catch (error) {
            console.error('Ошибка при загрузке глубоких активов:', error);
          }
        };
        loadDeepAssets();
      } 
      else {
        const loadAssets = async () => {
          try {
            const fetchedAssets = await fetchAssets(selectedAccount.account, selectedAccount.broker);
            setAssets(fetchedAssets);
          } catch (error) {
            console.error('Ошибка при загрузке активов:', error);
          }
        };
        loadAssets();
        const refreshMasterStatus = async () => {
          try {
            const newStatus = await fetchMasterStatus(selectedAccount.broker);
            setMasterSwitch(newStatus.status);
            console.log('Обновил нах', masterSwitch);
          } catch (error) {
            console.error('Ошибка при обновлении состояния брокера:', error);
            alert('Не удалось обновить состояние брокера. Проверьте логи.');
          }
        };
        refreshMasterStatus();
      }
    }
  }, [selectedAccount, activeMenu]);

  const handleAccountSelect = (account) => {
    setSelectedAccount(account);
    setActiveMenu(null);
    // const refreshMasterStatus = async (broker) => {
    //   try {
    //     const newStatus = await fetchMasterStatus(broker);
    //     setMasterSwitch(newStatus);
    //   } catch (error) {
    //     console.error('Ошибка при обновлении состояния брокера:', error);
    //     alert('Не удалось обновить состояние брокера. Проверьте логи.');
    //   }
    // };
    // refreshMasterStatus(account.broker);
    // setMasterSwitches({ okx: null, ib: null });
  };

  const handleToggleMenu = (menuName) => {
    setActiveMenu(activeMenu === menuName ? null : menuName);
  };

  const handleToggleActiveAssetClick = async (asset) => {
    try {
      const newStatus = await toggleActive(
        asset.symbol || asset.epic,
        asset.broker,
        asset.account || null,
        asset.sectype || ''
      );
      if (newStatus !== null) {
        setAssets((prevAssets) =>
          prevAssets.map((a) =>
            a.symbol === asset.symbol && a.broker === asset.broker
              ? { ...a, isAssetActive: newStatus }
              : a
          )
        );
      }
    } catch (error) {
      console.error('Ошибка при переключении активности актива:', error);
    }
  };


  const handleToggleOffensiveAssetClick = async (asset) => {
    try {
      const res = await toggleOffensive(
        asset.symbol || asset.epic,
        asset.broker,
        asset.account || null,
        asset.sectype || ''
      );
      if (res !== null) {
        await refreshAssets();
      } 
    } catch (error) {
      console.error('Ошибка при переключении offensive режима:', error);
    }
  };

  const handleToggleAlgoAssetClick = async (asset) => {
    try {
      const res = await toggleMaster(
        asset.symbol || asset.epic,
        asset.broker,
        asset.account || null,
        asset.sectype || ''
      );
      if (res !== null) {
        await refreshAssets();
      }
    } catch (error) {
      console.error('Ошибка при переключении алгоритма:', error);
    }
  };

  const handleToggleFestivalAssetClick = async (asset) => {
    try {
      const res = await toggleFestival(
        asset.symbol || asset.epic,
        asset.broker,
        asset.account || null,
        asset.sectype || ''
      );
      if (res !== null) {
        await refreshAssets();
      } else {
        alert('Не удалось переключить festival режим. Проверьте логи.');
      }
    } catch (error) {
      console.error('Ошибка при переключении фестиваля:', error);
    }
  };

  const refreshAssets = async () => {
    try {
      const fetchedAssets = await fetchAssets(selectedAccount.account, selectedAccount.broker);
      setAssets(fetchedAssets);
    } catch (error) {
      console.error('Ошибка при обновлении активов:', error);
      alert('Не удалось обновить активы. Проверьте логи.');
    }
  };

  const handleMasterSwitchClick = async (broker) => {
    try {
      const newStatus = await switchMaster(broker);
      setMasterSwitch(newStatus);
      // if (newStatus !== null) {
      //   setMasterSwitches((prev) => ({
      //     ...prev,
      //     [broker]: newStatus,
      //   }));
      // }
    } catch (error) {
      console.error('Ошибка при переключении мастера:', error);
    }
  };

  return (
    <div className="space-y-8">
      <h1 className="text-3xl font-bold">Config Page</h1>

      <div>
        <h2 className="text-xl font-semibold">Choose broker</h2>
        <AccountList accounts={accounts} onSelect={handleAccountSelect} />
      </div>

      {selectedAccount && (
        <div>
          <h2 className="text-xl font-semibold">Actions</h2>
          <div className="flex justify-center space-x-4">
            <Tile onClick={() => handleToggleMenu('algo')}>
              <div className="p-4 text-center cursor-pointer">Toggle algo</div>
            </Tile>
            <Tile onClick={() => handleToggleMenu('festival')}>
              <div className="p-4 text-center cursor-pointer">Toggle festival</div>
            </Tile>
            <Tile onClick={() => handleToggleMenu('active')}>
              <div className="p-4 text-center cursor-pointer">Toggle Active</div>
            </Tile>
            <Tile onClick={() => handleToggleMenu('offensive')}>
              <div className="p-4 text-center cursor-pointer">Toggle offensive</div>
            </Tile>
          </div>

          <div className="flex justify-center mt-4">
            <div className="w-2/3">
              {activeMenu === 'algo' && (
                <div className="mt-2 text-center">
                  <h3 className="text-lg font-semibold">Choose symbol</h3>
                  <div className="grid grid-cols-2 gap-4">
                    {assets.map((asset, index) => {
                      const displayName =
                        (asset.broker === 'ib' ? `${asset.account} - ` : '') +
                        (asset.broker === 'ig' ? asset.name : asset.symbol) +
                        (asset.isAlgoActive ? ' - TRADE' : ' - FADE');
                      return (
                        <Tile key={index} onClick={() => handleToggleAlgoAssetClick(asset)}>
                          <div className="p-4 text-center">{displayName}</div>
                        </Tile>
                      );
                    })}
                  </div>
                </div>
              )}
              {activeMenu === 'festival' && (
                <div className="mt-2 text-center">
                  <h3 className="text-lg font-semibold">Choose symbol</h3>
                  <div className="grid grid-cols-2 gap-4">
                    {assets.map((asset, index) => {
                      const displayName =
                        (asset.broker === 'ib' ? `${asset.account} - ` : '') +
                        (asset.broker === 'ig' ? asset.name : asset.symbol) +
                        (asset.isFestival ? ' - FEST' : ' - NO FEST');
                      return (
                        <Tile key={index} onClick={() => handleToggleFestivalAssetClick(asset)}>
                          <div className="p-4 text-center">{displayName}</div>
                        </Tile>
                      );
                    })}
                  </div>
                </div>
              )}
              {activeMenu === 'active' && (
                <div className="mt-2 text-center">
                  <h3 className="text-lg font-semibold">Choose symbol</h3>
                  <div className="grid grid-cols-2 gap-4">
                    {assets.map((asset) => {
                      const displayName =
                        (asset.broker === 'ib' ? `${asset.account} - ` : '') +
                        (asset.broker === 'ig' ? asset.name : asset.symbol) +
                        (asset.isAssetActive ? ' - ✅' : ' - ❌');
                      return (
                        <Tile
                          key={asset.id || `${asset.symbol}-${asset.broker}`}
                          onClick={() => handleToggleActiveAssetClick(asset)}
                          className={`p-4 text-center bg-white`}
                        >
                          <div>{displayName}</div>
                        </Tile>
                      );
                    })}
                  </div>
                </div>
              )}
              {activeMenu === 'offensive' && (
                <div className="mt-2 text-center">
                   <h3 className="text-lg font-semibold">Choose symbol</h3>
                   <div className="grid grid-cols-2 gap-4">
                    {assets.map((asset, index) => {
                      const displayName =
                        (asset.broker === 'ib' ? `${asset.account} - ` : '') +
                        (asset.broker === 'ig' ? asset.name : asset.symbol) +
                        (asset.isOffensive ? ' - OFFENSIVE' : ' - DEFENSIVE');
                      return (
                        <Tile key={index} onClick={() => handleToggleOffensiveAssetClick(asset)}>
                          <div className="p-4 text-center">{displayName}</div>
                        </Tile>
                      );
                    })}
                   </div>
                </div>
              )}
            </div>
          </div>

          {(selectedAccount.broker === 'okx' || selectedAccount.broker === 'ib') && masterSwitch !== null && (
            <div className="mt-4">
              <h3 className="text-lg font-semibold text-center">Master Switch</h3>
              <div className="flex justify-center space-x-4 mt-2">                
                  <Tile onClick={() => handleMasterSwitchClick(selectedAccount.broker)}>
                    <div className="p-4 text-center">
                      {selectedAccount.broker.toUpperCase()} Master Switch: {masterSwitch ? 'ON' : 'OFF'}
                    </div>
                  </Tile>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default ConfigPage;