import React, { useState, useEffect, useRef } from 'react';
import AccountList from '../components/Dashboard/AccountList';
import AssetList from '../components/Dashboard/AssetList';
import ControlPanel from '../components/Dashboard/ControlPanel';
import StatisticsTable from '../components/Dashboard/StatisticsTable';
import TradingViewWidget from '../components/Dashboard/TradingViewWidget';
import { 
  fetchAccounts,
  fetchAssets,
  fetchStatistics,
  fetchSources,
  changeActiveSource,
  toggleSide,
  toggleAngleBlock,
  fetchDeepAssets,
  toggleActive,
  toggleOffensive,
  toggleMaster,
  toggleFestival,
  switchMaster,
  fetchMasterStatus
} from '../services/api';
import { reversePatchCrypto, reversePatchSec } from '../utils/helpers';
import ConfigPanel from '../components/Dashboard/ConfigPanel';
import ToggleSwitch from '../components/common/ToggleSwitch';

function NewHomePage() {
  const [accounts, setAccounts] = useState([]);
  const [assets, setAssets] = useState([]);
  const [statistics, setStatistics] = useState([]);
  const [tvSymbol, setTVSymbol] = useState(null);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [selectedStrategy, setSelectedStrategy] = useState('gyrox-v2');
  const [selectedAsset, setSelectedAsset] = useState(null);
  // const [sources, setSources] = useState(null);

  const [unifiedData, setUnifiedData] = useState([]);

  const [isFetching, setIsFetching] = useState(false); 
  const [skipFetchFlag, setSkipFetchFlag] = useState(false);
  const [masterSwitch, setMasterSwitch] = useState(null);
  const [isUnified, setIsUnified] = useState(true);
  const [wsMounted, setwsMounted] = useState(false);

  useEffect(() => {
    const loadAccounts = async () => {
      const fetchedAccounts = await fetchAccounts();
      setAccounts(fetchedAccounts);
    };
    loadAccounts();
  }, []);

  useEffect(() => {
    const loadAssets = async () => {
      if (selectedAccount) {
        const fetchedAssets = await fetchAssets(
          selectedAccount.account,
          selectedAccount.broker
        );
        if (Array.isArray(fetchedAssets)) {
          setAssets(fetchedAssets);
        } else {
          setAssets([fetchedAssets])
        }
      }
    };
    setwsMounted(false);
    loadAssets();
  }, [selectedAccount]);

  useEffect(() => {
    const loadData = async () => {
      if (selectedAsset) {
        switch (selectedAsset.broker) {
          case "okx":
              setTVSymbol(`OKX:${reversePatchCrypto(selectedAsset.symbol)}`);
              break;
          case "ig":
          case "ib":
              const reversedPatch = reversePatchSec(
                  selectedAsset.symbol,
                  selectedAsset.sectype
              );
              setTVSymbol(`${(reversedPatch[1] === undefined) ?
                  (selectedAsset.exchange) :
                  (reversedPatch[1])}:${reversedPatch[0]}`);
              break;
          default:
              break;
        }
        // const fetchedSources = await fetchSources(
        //   selectedAccount.broker,
        //   selectedAccount.account,
        //   selectedAsset.symbol,
        //   selectedAsset.sectype
        // );
        // fetchedSources.sort((a,b) => a.sensitivity - b.sensitivity);
        // setSources(fetchedSources);
        const fetchedStatistics = await fetchStatistics(
          selectedAccount.broker,
          selectedAccount.account,
          selectedAsset.symbol,
          selectedAsset.sectype
        );
        setStatistics(fetchedStatistics);
      }
    };
    const refreshMasterStatus = async () => {
      try {
        const newStatus = await fetchMasterStatus(selectedAsset.broker);
        setMasterSwitch(newStatus.status);
        setwsMounted(true);
      } catch (error) {
        console.error('Ошибка при обновлении состояния брокера:', error);
      }
    };
    setwsMounted(false);
    if (!skipFetchFlag) {
      loadData();
      if (selectedAsset) {
        refreshMasterStatus();
      }
    } else {
      setSkipFetchFlag(false);
    }
  }, [selectedAsset]);

  const handleDeepFetch = async () => {
    if (selectedAccount) {
      setIsFetching(true); 
      try {
        const fetchedDeepAssets = await fetchDeepAssets(
          selectedAccount.broker,
          selectedAccount.account
        );
        setAssets(fetchedDeepAssets); 
      } catch (error) {
        console.error('Error during deep fetch:', error);
      } finally {
        setIsFetching(false); 
      }
    }
  };

  const handleAccountSelect = (account) => {
    setSelectedAccount(account);
    setSelectedAsset(null);
  };

  const handleAssetSelect = (asset) => {
    setTVSymbol(null);
    setStatistics(null);
    setSelectedAsset(asset);
  };

  const handleToggleAngleBlock = async () => {
    const result = await toggleAngleBlock(selectedAsset.symbol, selectedAsset.broker, selectedAsset.account, selectedAsset.sectype);
    const cloneAsset = structuredClone(selectedAsset);
    cloneAsset.isAngleBlock = result;
    setSelectedAsset(cloneAsset);
  };

  const handleChangeActivePos = async (sourceName, asset) => {
    if (!isUnified) {
      const result = await changeActiveSource(sourceName, selectedAsset.symbol, selectedAsset.broker, selectedAsset.account, selectedAsset.sectype);
      // const cloneSources = structuredClone(sources);
  
      // for (const [index, el] of sources.entries()) {
      //   if (el.isActive) {
      //     cloneSources[index].isActive = false;
      //     break;
      //   }
      // }
      // for (const [index, el] of sources.entries()) {
      //   if (el.source_name === result) {
      //     cloneSources[index].isActive = true;
      //     break;
      //   }
      // }
      // setSources(cloneSources);
    } else {
      const result = await changeActiveSource(sourceName, asset.symbol, asset.broker, asset.account, asset.sectype);
      // const cloneUnified = structuredClone(unifiedData);
      // for (const [index, el] of unifiedData.entries()) {
      //   if (el.asset.symbol === asset.symbol) {
      //     for (const [index_source, source] of el.sources.entries()) {
      //       if (source.isActive === true) {
      //         cloneUnified[index].sources[index_source].isActive = false; 
      //         break;
      //       }
      //     }
      //     break;
      //   }
      // }
      // for (const [index, el] of unifiedData.entries()) {
      //   if (el.asset.symbol === asset.symbol) {
      //     for (const [index_source, source] of el.sources.entries()) {
      //       if (source.source_name === result) {
      //         cloneUnified[index].sources[index_source].isActive = true;
      //         break;
      //       }
      //     }
      //     setUnifiedData(cloneUnified);
      //     break;
      //   }
      // }
    }
  };

  const handleToggleSide = async (sourceName, asset) => {
    try {
      if (!isUnified) {
        // const cloneSources = structuredClone(sources);
        const result = await toggleSide(sourceName, selectedAsset.symbol, selectedAsset.broker, selectedAsset.account, selectedAsset.sectype);
        // for (const [index, el] of sources.entries()) {
        //   if (el.source_name === sourceName) {
        //     cloneSources[index].side = result;
        //     break;
        //   }
        // }
        // setSources(cloneSources);
      } else {
        // const cloneUnified = structuredClone(unifiedData);
        const result = await toggleSide(sourceName, asset.symbol, asset.broker, asset.account, asset.sectype);
        // for (const [index, el] of unifiedData.entries()) {
        //   if (el.asset.symbol === asset.symbol) {
        //     for (const [index_source, source] of el.sources.entries()) {
        //       if (source.source_name === sourceName) {
        //         cloneUnified[index].sources[index_source].side = result;
        //         break;
        //       }
        //     }
        //     // setUnifiedData(cloneUnified);
        //     break;
        //   }
        // }
      }
    } catch (error) {
      console.error("Error toggling side:", error);
    }
  };

  const handleToggleActiveAssetClick = async () => {
    try {
      const newStatus = await toggleActive(
        selectedAsset.symbol || selectedAsset.epic,
        selectedAsset.broker,
        selectedAsset.account || null,
        selectedAsset.sectype || ''
      );
      if (newStatus !== null) {
        await handleDeepFetch();
        setSkipFetchFlag(true);
        handleAssetSelect(null);
        
      
      } else {
        alert('Не удалось переключить active режим. Проверьте логи.');
      }
    } catch (error) {
      console.error('Ошибка при переключении активности актива:', error);
    }
  };


  const handleToggleOffensiveAssetClick = async () => {
    try {
      const response = await toggleOffensive(
        selectedAsset.symbol || selectedAsset.epic,
        selectedAsset.broker,
        selectedAsset.account || null,
        selectedAsset.sectype || ''
      );
      if (response === null) {
        alert('Не удалось переключить offensive режим. Проверьте логи.');
      } else {
        const assetClone = structuredClone(selectedAsset);
        assetClone.isOffensive = response;
        setSkipFetchFlag(true);
        setSelectedAsset(assetClone);
      }
    } catch (error) {
      console.error('Ошибка при переключении offensive режима:', error);
    }
  };

  const handleToggleAlgoAssetClick = async () => {
    try {
      const response = await toggleMaster(
        selectedAsset.symbol || selectedAsset.epic,
        selectedAsset.broker,
        selectedAsset.account || null,
        selectedAsset.sectype || ''
      );
      if (response === null) {
        alert('Не удалось переключить fade режим. Проверьте логи.');
      } else {
        const assetClone = structuredClone(selectedAsset);
        assetClone.isAlgoActive = response;
        setSkipFetchFlag(true);
        setSelectedAsset(assetClone);
      }
    } catch (error) {
      console.error('Ошибка при переключении алгоритма:', error);
    }
  };

  const handleToggleFestivalAssetClick = async () => {
    try {
      const response = await toggleFestival(
        selectedAsset.symbol || selectedAsset.epic,
        selectedAsset.broker,
        selectedAsset.account || null,
        selectedAsset.sectype || ''
      );
      if (response === null) {
        alert('Не удалось переключить festival режим. Проверьте логи.');
      } else {
        const assetClone = structuredClone(selectedAsset);
        assetClone.isFestival = response;
        setSkipFetchFlag(true);
        setSelectedAsset(assetClone);
      }
    } catch (error) {
      console.error('Ошибка при переключении фестиваля:', error);
    }
  };

  const handleMasterSwitchClick = async (broker) => {
    try {
      const newStatus = await switchMaster(broker);
      setMasterSwitch(newStatus);
    } catch (error) {
      console.error('Ошибка при переключении мастера:', error);
    }
  };

  useEffect(() => {
    const loadUnifiedData = async () => {
      const result = [];
      for (let i = 0; i < assets.length; i++) {
        const entry = {};
        entry.asset = assets[i];
        // const fetchedSources = await fetchSources(
        //   selectedAccount.broker,
        //   selectedAccount.account,
        //   entry.asset.symbol,
        //   entry.asset.sectype
        // );
        // fetchedSources.sort((a,b) => a.sensitivity - b.sensitivity);
        // entry.sources = fetchedSources;
        result.push(entry);
      }
      setUnifiedData(result);
      setwsMounted(true);
    }
    if (isUnified) {
      setwsMounted(true);
      loadUnifiedData();
    }
  }, [isUnified, assets, selectedStrategy]);
  

  const handleUnifiedSwitchClick = (isUnifiedSelected) => {
    setIsUnified(isUnifiedSelected);
  };

  const handleStrategySwitchClick = (strategy) => {
    switch (strategy) {
      case false:
        setSelectedStrategy('gyrox-v1');
        break;
      case true:
        setSelectedStrategy('gyrox-v2');
        break;
      default:
        break;
    }
  };


  return (
    <div className="space-y-8">
      <h1 className="text-3xl font-bold">Dashboard</h1>
        <AccountList accounts={accounts} onSelect={handleAccountSelect} />
      {selectedAccount && (
        <div className='flex flex-col gap-1 sm:flex-row sm:gap-0'>
          <ToggleSwitch
            defaultOpt="left"
            onToggle={handleUnifiedSwitchClick}
            OptionTextOFF="UNIFIED"
            OptionTextON="SOLO"
          />
          <ToggleSwitch
            defaultOpt="left"
            onToggle={handleStrategySwitchClick}
            OptionTextOFF="GYROX ver.2"
            OptionTextON="GYROX ver.1"
          />
          <div className='flex items-center justify-between'>
          <button
            className={`p-3 bg-white rounded-lg shadow-lg ${
              isFetching ? 'opacity-30 cursor-not-allowed' : ''
            }`}
            onClick={handleDeepFetch}
            disabled={isFetching}
          >
            <div className="flex justify-center items-center">
              <img
                src="https://www.svgrepo.com/show/167751/refresh.svg"
                alt={isFetching ? "Refreshing" : "Refresh"}
                className={`h-6 w-6 ${isFetching ? "animate-spin" : ""}`}
                style={{ "animation-direction": "reverse" }}
              />
            </div>
          </button>
        </div>
        </div>
      )}
      {isUnified && wsMounted && (
        <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5'>
          {unifiedData
          .filter((entry) => {return entry.asset.isAssetActive})
          .map(entry => (
            <ControlPanel
              strategy={selectedStrategy}
              gridLocation=''
              asset={entry.asset}
              handleToggleAngleBlock={handleToggleAngleBlock}
              handleChangeActivePos={handleChangeActivePos}
              handleToggleSide={handleToggleSide}
            />
          ))}
        </div>
        
      )}
      {!isUnified && selectedAccount && (
        <AssetList
          assets={assets}
          onSelect={handleAssetSelect}
          detailed={false}
          handleDeepFetch={handleDeepFetch}
          isFetching={isFetching}
        />
      )}
      {!isUnified && selectedAsset && tvSymbol && (
        <TradingViewWidget tvSymbol={tvSymbol}/>
      )}
       {!isUnified && selectedAsset && masterSwitch !== null && (
          <div className="grid grid-cols-1 grid-rows-2 sm:grid-cols-3 sm:grid-rows-1">
            <ConfigPanel
              selectedAsset={selectedAsset}
              masterSwitch={masterSwitch}
              handleToggleAlgoAssetClick={handleToggleAlgoAssetClick}
              handleToggleFestivalAssetClick={handleToggleFestivalAssetClick}
              handleToggleActiveAssetClick={handleToggleActiveAssetClick}
              handleToggleOffensiveAssetClick={handleToggleOffensiveAssetClick}
              handleMasterSwitchClick={handleMasterSwitchClick}
            />
            {wsMounted && (
              <ControlPanel
                strategy={selectedStrategy}
                asset={selectedAsset}
                handleToggleAngleBlock={handleToggleAngleBlock}
                handleChangeActivePos={handleChangeActivePos}
                handleToggleSide={handleToggleSide}
              />
            )}
            </div>
      )}
          <div>
            {!isUnified && selectedAsset && tvSymbol && (
              <StatisticsTable data={statistics} />
            )}
          </div>
    </div>
  );
}

export default NewHomePage;