import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Legend,
  Label
} from 'recharts';

function TornadoChart({
  totalData,
  todayData,
  filename,
  DateGeneration,
  totalCallPutRatio,
  todayCallPutRatio,
  totalDollarVolumePuts,
  totalDollarVolumeCalls,
  todayVolumePuts,
  todayVolumeCalls,
 }) {
  const USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  return (
    <div className="my-4">
      <p className="text-xl ml-56 font-bold">Top Traded Dollar Volume by Strikes - {filename}</p>
      <p class="text-right ml-10">Generated on: {DateGeneration}</p>
      <br/>
      <br/>
      <h2 className="text-xl font-semibold">Top Traded Dollar Volume</h2>

      <div
        style={{
          position: 'absolute',
          left: '30%',
          top: '36%',
          fontSize: '30px',
          color: 'rgba(0, 0, 0, 0.3)',
          opacity: 0.7,
        }}
      >
        GRMCAP_AI_LAB
      </div>

      <ResponsiveContainer width="100%" height={500}>
        <BarChart
          data={totalData.slice().reverse()}
          layout="vertical"
          margin={{ top: 20, right: 30, left: 50, bottom: 20 }}
          barCategoryGap={0}
          maxBarSize={100}
        >
          <XAxis
            type="number"
            tickFormatter={(value) => `$${value.toLocaleString()}`}
            axisLine={{ strokeWidth: 2 }}
          />
          <YAxis
            type="category"
            dataKey="Strike"
            width={100}
            tick={{ fontSize: 14, fontWeight: 'bold' }}
            axisLine={{ strokeWidth: 2 }}
          >
            <Label
              style={{
                textAnchor: "middle",
                fontSize: "130%",
                fill: "black",
              }}
              angle={270} 
              value={"Strike"}
            />
          </YAxis>
          <Tooltip formatter={(value, name) => [`${USDollar.format(Math.abs(value).toFixed(2))}`, name]} />
          <Legend />
          <Bar
            name={`Calls (Total $ Volume: ${USDollar.format(todayVolumeCalls)})`}
            dataKey="Calls"
            fill="#4EB543"
            stackId="a"
          />
          <Bar
            name={`Puts (Total $ Volume: ${USDollar.format(todayVolumePuts)})`}
            dataKey="Puts"
            fill="#302683"
            stackId="a"
          />
          <Bar
            name={`Call/Put Ratio ${todayCallPutRatio.toFixed(2)}`}
            dataKey="CPR"
            fill="#FFA500"
            stackId="a"
          />
        </BarChart>
      </ResponsiveContainer>

      <div
        style={{
          position: 'absolute',
          left: '30%',
          top: '94%',
          fontSize: '30px',
          color: 'rgba(0, 0, 0, 0.3)',
          opacity: 0.7,
        }}
      >
        GRMCAP_AI_LAB
      </div>

      <h2 className="text-xl font-semibold mt-8">Top Open Interest</h2>
      <ResponsiveContainer width="100%" height={500}>
        <BarChart
          data={todayData.slice().reverse()}
          layout="vertical"
          margin={{ top: 20, right: 30, left: 50, bottom: 20 }}
          barCategoryGap={0}
          maxBarSize={100}
        >
          <XAxis
            type="number"
            tickFormatter={(value) => `$${value.toLocaleString()}`}
            axisLine={{ strokeWidth: 2 }}
          />
          <YAxis
            name="Strike"
            type="category"
            dataKey="Strike"
            width={100}
            tick={{ fontSize: 14, fontWeight: 'bold' }}
            axisLine={{ strokeWidth: 2 }}
          >
            <Label
              style={{
                textAnchor: "middle",
                fontSize: "130%",
                fill: "black",
              }}
              angle={270} 
              value={"Strike"}
            />
          </YAxis>
          <Tooltip formatter={(value, name) => [`${USDollar.format(Math.abs(value).toFixed(2))}`, name]} />
          <Legend />
          <Bar
            name={`Calls (Total $ Volume: ${USDollar.format(totalDollarVolumeCalls)})`}
            dataKey="Calls"
            fill="#4EB543"
            stackId="a"
          />
          <Bar
            name={`Puts (Total $ Volume: ${USDollar.format(totalDollarVolumePuts)})`}
            dataKey="Puts"
            fill="#302683"
            stackId="a"
          />
          <Bar
            name={`Call/Put Ratio ${totalCallPutRatio.toFixed(2)}`}
            dataKey="CPR"
            fill="#FFA500"
            stackId="a"
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}
export default TornadoChart;