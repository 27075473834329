import React, { useState, useEffect, useRef, useCallback } from 'react';
import AccountList from '../components/Dashboard/AccountList';
import AssetList from '../components/Dashboard/AssetList';
import ControlPanel from '../components/Dashboard/ControlPanel';
import StatisticsTable from '../components/Dashboard/StatisticsTable';
import TradingViewWidget from '../components/Dashboard/TradingViewWidget';
import { 
  fetchAccounts,
  fetchAssets,
  fetchStatistics,
  changeActiveSource,
  toggleSide,
  fetchDeepAssets,
  toggleActive,
  toggleOffensive,
  toggleMaster,
  toggleFestival,
  switchMaster,
  fetchMasterStatus,
  updateAssetConfig,
  fetchUserProfile,
  toggleSpinDown,
  setTimeframeSide,
  setJ2F,
  getAuthToken,
  setJoeRider
} from '../services/api';
import { reversePatchCrypto, reversePatchSec, timeframes } from '../utils/helpers';
import ConfigPanel from '../components/Dashboard/ConfigPanel';
import ToggleSwitch from '../components/common/ToggleSwitch';
import OptionsForm from '../components/Dashboard/OptionsForm';
import KPIStatistic from '../components/Dashboard/KPIStatistic';

function NewHomePage() {
  const timeframes_ws = useRef();

  const [timeframesInfo, setTimeframesInfo] = useState(null);
  const [timeframesWsConnected, setTimeframesWsConnected] = useState(false);

  const [accounts, setAccounts] = useState([]);
  const [assets, setAssets] = useState([]);
  const [statistics, setStatistics] = useState([]);
  const [tvSymbol, setTVSymbol] = useState(null);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [selectedStrategy, setSelectedStrategy] = useState('gyrox-v2');
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [unifiedData, setUnifiedData] = useState([]);
  
  const [userProfile, setUserProfile] = useState(null);

  const [isFetching, setIsFetching] = useState(false); 
  const [skipFetchFlag, setSkipFetchFlag] = useState(false);
  const [masterSwitch, setMasterSwitch] = useState(null);
  const [viewMode, setViewMode] = useState('unified');
  const [wsMounted, setwsMounted] = useState(false);

  const [seniorTimeframe, setSeniorTimeframe] = useState(null);

  // const [lampOn, setLampOn] = useState(false);
  // const [activeFlags, setActiveFlags] = useState([]);

  useEffect(() => {
    const loadAccounts = async () => {
      const fetchedAccounts = await fetchAccounts();
      setAccounts(fetchedAccounts);
    };
    const loadUserProfile = async () => {
      const profile = await fetchUserProfile();
      setUserProfile(profile);
    };
    loadAccounts();
    loadUserProfile();

    return () => {
      if (timeframesWsConnected) {
        timeframes_ws.current.close();
      }
    };
  }, []);

  useEffect(
    () => {
      if (viewMode === 'solo' && timeframesWsConnected) {
        timeframes_ws.current.close();
        setTimeframesInfo(null);
        setTimeframesWsConnected(false);
        if (selectedAsset !== null) {
          connectTimeframesWs();
        }
      }
      if (viewMode === 'solo' && !timeframesWsConnected) {
        connectTimeframesWs();
      }
    }, [selectedAsset]
  )

  const connectTimeframesWs = () => {
    timeframes_ws.current = new WebSocket(`${process.env.REACT_APP_API_WS_BASE_URL}/fetch-timeframes-socket`)
    console.log("Connection estabilished");
    const payload = {};
    payload.symbol = selectedAsset.symbol;
    payload.broker = selectedAsset.broker;
    payload.sectype = selectedAsset.sectype;
    payload.account = selectedAsset.broker === 'okx' ? userProfile.name : selectedAsset.account;
    payload.strategy = selectedStrategy;
    payload.Authentication = getAuthToken();
    timeframes_ws.current.onopen = () => {timeframes_ws.current.send(JSON.stringify(payload)); setTimeframesWsConnected(true);};
    gettingTimeframesData();
  }

  const gettingTimeframesData = useCallback(() => {
    if (!timeframes_ws.current) return;
    timeframes_ws.current.onmessage = (body) => {
      const data = body.data;
      if (data !== 'ping') {
        const parsedData = JSON.parse(data);
        setTimeframesInfo(parsedData);
        // const { is_j2f, is_spin_down, is_algo_active } = parsedData;
        // const activeFlagNames = [];
        // if (is_j2f) activeFlagNames.push('J2F');
        // if (is_spin_down) activeFlagNames.push('Spin Down');
        // if (is_algo_active) activeFlagNames.push('Algo Active');
        // setActiveFlags(activeFlagNames);
        // setLampOn(activeFlagNames.length > 0);
      }
    };
  }, []);


  useEffect(() => {
    const loadAssets = async () => {
      if (viewMode === 'helicopter') {
        const assetPromises = accounts.map(account =>
          fetchAssets(account.account, account.broker)
        );
        const assetsArrays = await Promise.all(assetPromises);
        const allAssets = assetsArrays.flat();
        setAssets(allAssets);
      } else if (selectedAccount && (viewMode === 'unified' || viewMode === 'solo')) {
        const fetchedAssets = await fetchAssets(
          selectedAccount.account,
          selectedAccount.broker
        );
        if (Array.isArray(fetchedAssets)) {
          setAssets(fetchedAssets);
        } else {
          setAssets([fetchedAssets]);
        }
      } else {
        setAssets([]);
      }
    };
    setwsMounted(false);
    loadAssets();
  }, [viewMode, selectedAccount, accounts]);

  useEffect(() => {
    const loadData = async () => {
      if (selectedAsset) {
        switch (selectedAsset.broker) {
          case "okx":
            setTVSymbol(`OKX:${reversePatchCrypto(selectedAsset.symbol)}`);
            break;
          case "ig":
          case "ib":
            const reversedPatch = reversePatchSec(
              selectedAsset.symbol,
              selectedAsset.sectype
            );
            setTVSymbol(`${(reversedPatch[1] === undefined) ?
              (selectedAsset.exchange) :
              (reversedPatch[1])}:${reversedPatch[0]}`);
            break;
          default:
            break;
        }
        const fetchedStatistics = await fetchStatistics(
          selectedAccount.broker,
          selectedAsset.broker === 'okx' ? userProfile.name : selectedAccount.account,
          selectedAsset.symbol,
          selectedAsset.sectype
        );
        setStatistics(fetchedStatistics.raw_data);
      }
    };
    const refreshMasterStatus = async () => {
      try {
        const newStatus = await fetchMasterStatus(selectedAsset.broker);
        setMasterSwitch(newStatus.status);
        setwsMounted(true);
      } catch (error) {
        console.error('Ошибка при обновлении состояния брокера:', error);
      }
    };
    setwsMounted(false);
    if (!skipFetchFlag) {
      loadData();
      if (selectedAsset) {
        refreshMasterStatus();
      }
    } else {
      setwsMounted(true);
      setSkipFetchFlag(false);
    }
  }, [selectedAsset]);

  useEffect(() => {
    if (viewMode === 'solo' && timeframesInfo) {
      const activeIndex = timeframes.indexOf(timeframesInfo.active_timeframe);
      const nextIndex = Math.min(activeIndex + 1, timeframes.length - 1);
      setSeniorTimeframe(timeframes[nextIndex]);
    }
  }, [timeframesInfo]);

  const handleDeepFetch = async () => {
    if (selectedAccount) {
      setIsFetching(true); 
      try {
        const fetchedDeepAssets = await fetchDeepAssets(
          selectedAccount.broker,
          selectedAccount.account
        );
        setAssets(fetchedDeepAssets); 
      } catch (error) {
        console.error('Error during deep fetch:', error);
      } finally {
        setIsFetching(false); 
      }
    }
  };

  const handleAccountSelect = (account) => {
    setSelectedAccount(account);
    setSelectedAsset(null);
  };

  const handleAssetSelect = (asset) => {
    setTVSymbol(null);
    setStatistics(null);
    setwsMounted(false);
    setSeniorTimeframe(null);
    setSelectedAsset(asset);
  };

  const handleUpdateConfig = async (symbol, broker, basePosition, step, timeframe, account, sectype) => {
    await updateAssetConfig(symbol, broker, basePosition, step, timeframe, selectedStrategy, broker === 'okx' ? userProfile.name : account, sectype);
    // const localAsset = structuredClone(selectedAsset);
    // localAsset.base_quantity = basePosition;
    // localAsset.step_quantity = step;
    // selectedStrategy === 'gyrox-v1' ?
    //   localAsset.active_timeframe_v1 = timeframe :
    //   localAsset.active_timeframe_v2 = timeframe;
    // setSelectedAsset(localAsset);
    // setwsMounted(false);
  };

  const handleChangeActivePos = async (sourceName, asset, timeframe) => {
    if (viewMode === 'solo') {
      await changeActiveSource(sourceName, selectedAsset.symbol, selectedAsset.broker, timeframe, selectedStrategy, selectedAsset.broker === 'okx' ? userProfile.name : selectedAsset.account, selectedAsset.sectype);
    } else {
      await changeActiveSource(sourceName, asset.symbol, asset.broker, timeframe, selectedStrategy, asset.broker === 'okx' ? userProfile.name : asset.account, asset.sectype);
    }
  };

  const handleToggleSide = async (sourceName, asset, timeframe) => {
    try {
      if (viewMode === 'solo') {
        await toggleSide(sourceName, selectedAsset.symbol, selectedAsset.broker, timeframe, selectedAsset.account, selectedAsset.sectype);
      } else {
        await toggleSide(sourceName, asset.symbol, asset.broker, timeframe, asset.account, asset.sectype);
      }
    } catch (error) {
      console.error("Error toggling side:", error);
    }
  };

  const handleSetTimeframeSideClick = async (side, timeframe) => {
    try {
      await setTimeframeSide(
        side,
        selectedAsset.symbol,
        selectedAsset.broker,
        selectedStrategy,
        timeframe,
        selectedAsset.broker === 'okx' ? userProfile.name : selectedAsset.account,
        selectedAsset.sectype
      );
    } catch (error) {
      console.error("Error toggling side:", error);
    }
  };

  const handleSetJ2F = async (timeframe, isJ2F) => {
    try {
      const result = await setJ2F(
        isJ2F,
        selectedAsset.symbol,
        selectedAsset.broker,
        timeframe,
        selectedAsset.broker === 'okx' ? userProfile.name : selectedAsset.account,
        selectedAsset.sectype
      );
    } catch (error) {
      console.error("Error setting J2F:", error);
    }
  };

  const handleSetJoeRider = async (isJoeRider) => {
    try {
      const result = await setJoeRider(
        isJoeRider,
        selectedAsset.symbol,
        selectedAsset.broker,
        selectedAsset.broker === 'okx' ? userProfile.name : selectedAsset.account,
        selectedAsset.sectype
      );
    } catch (error) {
      console.error("Error setting Joe Rider:", error);
    }
  };

  const handleToggleSpinDown = async () => {
    try {
      const response = await toggleSpinDown(
        selectedAsset.symbol,
        selectedAsset.broker,
        selectedAsset.account,
        selectedAsset.sectype
      );

    } catch (error) {
      console.error("Error toggling spin down:", error);
    }
  };


  const handleToggleActiveAssetClick = async () => {
    try {
      const newStatus = await toggleActive(
        selectedAsset.symbol || selectedAsset.epic,
        selectedAsset.broker,
        selectedAsset.account || null,
        selectedAsset.sectype || ''
      );
      if (newStatus !== null) {
        await handleDeepFetch();
        setSkipFetchFlag(true);
        handleAssetSelect(null);
        
      
      } else {
        alert('Не удалось переключить active режим. Проверьте логи.');
      }
    } catch (error) {
      console.error('Ошибка при переключении активности актива:', error);
    }
  };


  const handleToggleOffensiveAssetClick = async () => {
    try {
      const response = await toggleOffensive(
        selectedAsset.symbol || selectedAsset.epic,
        selectedAsset.broker,
        selectedAsset.account || null,
        selectedAsset.sectype || ''
      );
      if (response === null) {
        alert('Не удалось переключить offensive режим. Проверьте логи.');
      } else {
        const assetClone = structuredClone(selectedAsset);
        assetClone.isOffensive = response;
        setSkipFetchFlag(true);
        setSelectedAsset(assetClone);
      }
    } catch (error) {
      console.error('Ошибка при переключении offensive режима:', error);
    }
  };

  const handleToggleAlgoAssetClick = async () => {
      const response = await toggleMaster(
      selectedAsset.symbol || selectedAsset.epic,
      selectedAsset.broker,
      selectedAsset.account || null,
      selectedAsset.sectype || ''
    );
  };

  const handleToggleFestivalAssetClick = async () => {
    try {
      const response = await toggleFestival(
        selectedAsset.symbol || selectedAsset.epic,
        selectedAsset.broker,
        selectedAsset.account || null,
        selectedAsset.sectype || ''
      );
      if (response === null) {
        alert('Не удалось переключить festival режим. Проверьте логи.');
      } else {
        const assetClone = structuredClone(selectedAsset);
        assetClone.isFestival = response;
        setSkipFetchFlag(true);
        setSelectedAsset(assetClone);
      }
    } catch (error) {
      console.error('Ошибка при переключении фестиваля:', error);
    }
  };

  const handleMasterSwitchClick = async (broker) => {
    try {
      const newStatus = await switchMaster(broker);
      setMasterSwitch(newStatus);
    } catch (error) {
      console.error('Ошибка при переключении мастера:', error);
    }
  };

  useEffect(() => {
    const loadUnifiedData = async () => {
      const result = [];
      for (let i = 0; i < assets.length; i++) {
        const entry = {};
        entry.asset = assets[i];
        result.push(entry);
      }
      setUnifiedData(result);
      setwsMounted(true);
    };
    if (viewMode === 'unified' || viewMode === 'helicopter') {
      loadUnifiedData();
    } else {
      setwsMounted(true);
    }
  }, [viewMode, assets, selectedStrategy]);


  const handleViewModeToggle = (index) => {
    const modes = ['solo', 'unified', 'helicopter'];
    setViewMode(modes[index]);
  };

  return (
    <div className="space-y-8">
      <h1 className="text-3xl font-bold">Dashboard</h1>
      

      <AccountList accounts={accounts} onSelect={handleAccountSelect} />
      {!selectedAccount &&(
      <ToggleSwitch
        options={['Solo', 'Unified', 'Helicopter']}
        defaultOption="middle" 
        onToggle={handleViewModeToggle}
      />)}
      
      {(viewMode === 'unified' || viewMode === 'solo') && selectedAccount && (
        <div className='flex flex-col gap-1 sm:flex-row sm:gap-4'>
          <ToggleSwitch
            options={['Solo', 'Unified', 'Helicopter']}
            defaultOption="middle" 
            onToggle={handleViewModeToggle}
          />
          <div className='flex items-center justify-between'>
            <button
              className={`p-3 bg-white rounded-lg shadow-lg ${
                isFetching ? 'opacity-30 cursor-not-allowed' : ''
              }`}
              onClick={handleDeepFetch}
              disabled={isFetching}
            >
              <div className="flex justify-center items-center">
                <img
                  src="https://www.svgrepo.com/show/167751/refresh.svg"
                  alt={isFetching ? "Refreshing" : "Refresh"}
                  className={`h-6 w-6 ${isFetching ? "animate-spin" : ""}`}
                  style={{ "animation-direction": "reverse" }}
                />
              </div>
            </button>
          </div>
        </div>
      )}

      

      {(viewMode === 'unified' || viewMode === 'solo') && !selectedAccount && (
        <div className="bg-gradient-to-r from-gray-50 to-gray-100 p-6 rounded-xl shadow-md text-center text-gray-700 font-semibold text-lg border border-gray-200">
          Please select an account to view assets
        </div>
      )}

      
      {(viewMode === 'unified' || viewMode === 'helicopter') && wsMounted && (
        <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5'>
          {unifiedData
            .filter((entry) => {return entry.asset.isAssetActive})
            .map(entry => (
              <ControlPanel
                strategy={selectedStrategy}
                gridLocation=''
                timeframe={selectedStrategy === 'gyrox-v1' ? entry.asset.active_timeframe_v1 : entry.asset.active_timeframe_v2}
                asset={entry.asset}
                handleChangeActivePos={handleChangeActivePos}
                handleToggleSide={handleToggleSide}
                isUnified={true}
                username={userProfile.name}
              />
            ))}
        </div>
      )}
      {viewMode === 'solo' && selectedAccount && (
        <AssetList
          assets={assets}
          onSelect={handleAssetSelect}
          detailed={false}
          handleDeepFetch={handleDeepFetch}
          isFetching={isFetching}
        />
      )}
      {viewMode === 'solo' && selectedAsset && tvSymbol && (
        <TradingViewWidget tvSymbol={tvSymbol}/>
      )}
      {viewMode === 'solo' && selectedAsset && masterSwitch !== null && wsMounted && timeframesInfo && seniorTimeframe && (
        <div className="grid grid-cols-1 grid-rows-5 sm:grid-cols-3 sm:grid-rows-2">
          {(timeframes.indexOf(timeframesInfo.active_timeframe) - 1) > 0 && (
            <ControlPanel
              gridLocation={'sm:col-start-1 sm:col-end-1 sm:row-start-1 sm:row-end-1 col-start-1 col-end-1 row-start-1 row-end-1'}
              strategy={selectedStrategy}
              timeframe={timeframes[timeframes.indexOf(timeframesInfo.active_timeframe) - 1]}
              asset={selectedAsset}
              handleChangeActivePos={handleChangeActivePos}
              handleToggleSide={handleToggleSide}
              isUnified={false}
              username={userProfile.name}
            />
          )}
          <ControlPanel
            gridLocation={'sm:col-start-2 sm:col-end-2 sm:row-start-1 sm:row-end-1 col-start-1 col-end-1 row-start-2 row-end-2'}
            strategy={selectedStrategy}
            timeframe={timeframes[timeframes.indexOf(timeframesInfo.active_timeframe)]}
            asset={selectedAsset}
            handleChangeActivePos={handleChangeActivePos}
            handleToggleSide={handleToggleSide}
            isUnified={false}
            username={userProfile.name}
          />
            
          <ControlPanel
            gridLocation={'sm:col-start-3 sm:col-end-3 sm:row-start-1 sm:row-end-1 col-start-1 col-end-1 row-start-3 row-end-3'}
            strategy={selectedStrategy}
            timeframe={seniorTimeframe || timeframesInfo.active_timeframe}
            asset={selectedAsset}
            handleChangeActivePos={handleChangeActivePos}
            handleToggleSide={handleToggleSide}
            isUnified={false}
            username={userProfile.name}
            isSenior={true}
            onTimeframeChange={setSeniorTimeframe}
            handleSetTimeframeSideClick={handleSetTimeframeSideClick}
          />
          {(timeframes.indexOf(timeframesInfo.active_timeframe) + 1) >= timeframes.length && (
            <div className='sm:col-start-3 sm:col-end-3 sm:row-start-1 sm:row-end-1 col-start-1 col-end-1 row-start-3 row-end-3'></div>
          )}
          <ConfigPanel
            selectedAsset={selectedAsset}
            masterSwitch={masterSwitch}
            gridLocation={'sm:col-start-1 sm:col-end-1 sm:row-start-2 sm:row-end-2 col-start-1 col-end-1 row-start-4 row-end-4'}
            handleToggleAlgoAssetClick={handleToggleAlgoAssetClick}
            handleToggleSpinDownClick={handleToggleSpinDown}
            handleToggleActiveAssetClick={handleToggleActiveAssetClick}
            handleSetTimeframeSideClick={handleSetTimeframeSideClick}
            handleMasterSwitchClick={handleMasterSwitchClick}
            handleSetJ2F={handleSetJ2F}
            handleSetJoeRider={handleSetJoeRider}
            timeframesInfo={timeframesInfo}
          />
          <OptionsForm
            asset={selectedAsset}
            timeframesInfo={timeframesInfo}
            timeframeOptions={timeframes}
            handleUpdateConfig={handleUpdateConfig}
          />
          <KPIStatistic 
            broker={selectedAsset.broker} 
            account={selectedAsset.broker === 'okx' ? userProfile.name : selectedAccount.account} 
            symbol={selectedAsset.symbol} 
            sectype={selectedAsset.sectype}
          />
        </div>
      )}
      <div>
        {viewMode === 'solo' && selectedAsset && tvSymbol && (
          <StatisticsTable data={statistics} />
        )}
      </div>
    </div>
  );
}

export default NewHomePage;