import { useEffect, useState } from 'react';
import { fetchStatistics } from '../../services/api';

const KPIStatistic = ({ broker, account, symbol, sectype }) => {
    const [data, setData] = useState(null);

    useEffect(() => {
        fetchStatistics(broker, account, symbol, sectype)
            .then(setData)
            .catch(console.error);
    }, [broker, account, symbol, sectype]);

    return (
        <div className="p-4 bg-white border-2 border-gray-300 shadow-lg rounded-lg w-3/4 h-[240px] text-left relative flex flex-col items-center justify-center pt-[10px] pl-[20px]">
        {data && data.statistics?.length ? (
            <>  
            <br />
                <div className="mb-5 w-full">
                    <p className="font-semibold text-lg">Average Time In Trade</p>
                    <p>{data.avg_trade_time}</p>
                </div>
                {data.statistics.map((i, index) => (
                    <div key={index} className="mb-5 w-full">
                        <p className="font-semibold text-lg">Profitable trades/Loss Trades</p>
                        <p className={i.profit_loss_ratio >= 1 ? "text-[#009b76] font-bold" : "text-[#F0122D] font-bold"}>
                            {i.profit_loss_ratio.toFixed(3)}
                        </p>
                        <br />
                        <p className="font-semibold text-lg">Profit/Loss</p>
                        <p className={i.ProfLoss >= 1 ? "text-[#009b76] font-bold" : "text-[#F0122D] font-bold"}>
                            {i.ProfLoss.toFixed(3)}
                        </p>
                    </div>
                ))}
            </>
        ) : (
            <div className="flex flex-col items-center">
                <span className="w-12 h-12 border-4 border-gray-300 border-t-[#FF3D00] rounded-full animate-spin"></span>
            </div>
        )}
    </div>

    );
    
};


export default KPIStatistic;