import Tile from '../common/Tile';
import { shortenSourceName } from '../../utils/helpers';
import { useState, useRef, useEffect, useCallback } from 'react';
import { getAuthToken } from '../../services/api';
import { timeframes } from '../../utils/helpers';
import ConfigPanelForColors from './ConfigPanelForColors';

const ControlPanel = ({
  strategy,
  asset,
  handleToggleSide,
  handleChangeActivePos,
  gridLocation,
  timeframe,
  isUnified,
  username,
  isSenior = false,
  onTimeframeChange = () => {},
  handleSetTimeframeSideClick = () => {}
}) => {
  const ws = useRef();

  const [sources, setSources] = useState([]);
  const [statuses, setStatuses] = useState([])
  const [gyroxArray, setGyroxArray] = useState([]);
  const [isActiveMenuOpen, setIsActiveMenuOpen] = useState(false);
  const [wsConnected, setwsConnected] = useState(false);
  const [activeTimeframe, setActiveTimeframe] = useState(timeframe)

  useEffect(
    () => {
      if (!wsConnected) {
        connectWebsocket();
      }
      return () => {
        if (wsConnected) {
          ws.current.close();
        }
      }
      }, []
    );

    useEffect(
      () => {
        if (wsConnected && !isUnified) {
          ws.current.close();
          setwsConnected(false);
          // setActiveTimeframe(timeframe);
          connectWebsocket();
        }
      }, [timeframe]
    )

    useEffect(
      () => {
        if (wsConnected && isUnified) {
          ws.current.close();
          setwsConnected(false);
          connectWebsocket();
        }
      }, [activeTimeframe]
    )

  const connectWebsocket = () => {
    ws.current = new WebSocket(`${process.env.REACT_APP_API_WS_BASE_URL}/fetch-sources-socket`);
    const payload = {};
    payload.symbol = asset.symbol;
    payload.sectype = asset.sectype;
    payload.broker = asset.broker;
    payload.account = asset.broker === 'ib' ? asset.account : username;
    payload.strategy = strategy;
    payload.timeframe = isUnified ? activeTimeframe : timeframe;
    payload.isUnified = isUnified;
    payload.Authentication = getAuthToken();
    ws.current.onopen = () => {ws.current.send(JSON.stringify(payload)); setwsConnected(true); console.log(`connection estabilished for ${asset.symbol}`);};
    ws.current.onclose = () => {console.log(`connection released for ${asset.symbol}`)}
    gettingSourcesData();
  };

  const gettingSourcesData = useCallback(() => {
    if (!ws.current) return;

    ws.current.onmessage = body => {
      const data = body.data;
      if (data !== 'ping') {
        const message = JSON.parse(body.data);
        var sourcesWs = message.sources;
        setSources(sourcesWs.filter(item => item.strategy === 'gyrox-v1').sort((a, b) => a.sensitivity - b.sensitivity)); 
        setStatuses(message.statuses);
        if (isUnified) {
          setActiveTimeframe(message.statuses.active_timeframe);
        }
        const temp_families = Array.from(new Set(structuredClone(sourcesWs).sort((a, b) => a.sensitivity - b.sensitivity).map((entry) => entry.source_name.length === 2 ? entry.source_name[0] : 'x')))
        // const temp_families = Array.from(new Set(message.sort((a, b) => a.sensitivity - b.sensitivity).map((entry) => entry.source_name.length === 2 ? entry.source_name[0] : 'x')))
        const temp_gyrox_array = [];
        sourcesWs = sourcesWs.filter(item => item.strategy === 'gyrox-v2').sort((a, b) => a.sensitivity - b.sensitivity);
        for (let i = 0; sourcesWs.length > 0; i++) {
          const prefix = temp_families[i];
          const items = sourcesWs.filter(item => item.source_name.startsWith(prefix));
          temp_gyrox_array.push(items);
          sourcesWs = sourcesWs.filter(item => !item.source_name.startsWith(prefix));
        }
        setGyroxArray(temp_gyrox_array);
      }
    };
  }, [])
  

  useEffect(() => {
    return () => {
      ws.current.close();
    };
  }, [])
  
//  statuses.is_algo_active = !statuses.is_algo_active;
  
  if (gridLocation === undefined) {
    gridLocation = "sm:col-start-2 sm:col-end-2 sm:row-start-1 col-start-1 col-end-1 row-start-1 row-end-1";
  }
  return (
    <div className={`${gridLocation}`}>
      <div className="bg-gray-300 bg-opacity-50 shadow-md border-gray-300 border-opacity-20 border-4 px-2 pb-2 mb-4 rounded-3xl mr-4 relative">
        <div className="m-auto">
            <div className={`${isSenior ? "grid grid-cols-3" : "" }`}>
              {statuses && (
                <div className={`absolute ${isSenior ? "col-start-1 col-end-1" : ""} grid grid-flow-col grid-rows-3 grid-cols-2`}>
                  {Object.entries(statuses)
                    .filter((entry) => ((!!entry[1] && entry[0] !== "active_timeframe" && entry[0] !== "is_algo_active") || (entry[0] === "is_algo_active" && entry[1] === false)))
                    .map((entry) => {
                      return (
                        // <div key={entry[0]} className="flex flex-col items-center">
                          // <div className="w-3 h-3 bg-green-500 rounded-full shadow-sm"></div>
                          <div className="text-sm font-bold text-black-700 mt-1">
                            {
                              entry[0] === "is_j2f" ? "J" :
                              entry[0] === "is_spin_down" ? "S" :
                              entry[0] === "is_algo_active" ? "F" :
                              entry[0] === "is_pending_exit" ? "PE" :
                              entry[0] === "is_optimal_timeframe" ? "OT" :
                              entry[0] === "is_defense_block" ? "DB" :
                              entry[0] === "active_timeframe" ? "" : ""
                            }
                          </div>
                        // </div>
                      );
                    })}
                </div>
                )
              }
            {/* <div className="font-bold font-sans pt-4 pb-2 tracking-widest align-middle text-sm lg:text-md md:text-md sm:text-sm text-gray-800 text-center">
              {asset.broker === 'okx'
                ? asset.symbol.substring(0, asset.symbol.indexOf('/'))
                : asset.symbol}{' '}
              - {isUnified ? activeTimeframe : timeframe}
              
            </div> */}
            <div className={`font-bold ${isSenior ? "col-start-2 col-end-2" : ""} font-sans pt-4 pb-2 tracking-widest align-middle text-sm lg:text-md md:text-md sm:text-sm text-gray-800 text-center`}>
              {asset.broker === 'okx' 
                ? asset.symbol.substring(0, asset.symbol.indexOf('/')) 
                : asset.symbol}
              {isSenior ? (
                <select
                  value={timeframe}
                  onChange={(e) => onTimeframeChange(e.target.value)}
                  className="ml-2 bg-gray-100 rounded border border-gray-300 text-sm px-1 py-0.5"
                >
                  {timeframes.map(tf => (
                    <option key={tf} value={tf}>{tf}</option>
                  ))}
                </select>
              ) : (
                ` - ${isUnified ? activeTimeframe : timeframe}`
              )}
            </div>
            {isSenior && (
              <div className='col-start-3 col-end-3 justify-items-center'>
                <ConfigPanelForColors
                  handleSetTimeframeSideClick={handleSetTimeframeSideClick}
                  timeframe={timeframe}
                />
              </div>
            )}
          </div>
  
          <div
            className={`${
              strategy === 'gyrox-v1' || (isUnified ? activeTimeframe : timeframe) === 'ZERO'
                ? 'hidden'
                : 'align-middle grid grid-cols-4 pb-2 text-center font-mono tracking-wide text-l'
            }`}
          >
            <div className="col-start-2 col-end-2 rounded-tl-2xl border-l-2 border-t-2 border-l-gray-300 border-t-gray-300">
              1
            </div>
            <div className="col-start-3 col-end-3 border-t-2 border-t-gray-300">
              2
            </div>
            <div className="col-start-4 col-end-4 rounded-tr-2xl border-t-2 border-r-2 border-t-gray-300 border-r-gray-300">
              3
            </div>
          </div>
        </div>
  
        {strategy === 'gyrox-v1' && (isUnified ? activeTimeframe : timeframe) !== 'ZERO' &&
        sources
        .map(source => (
          <div key={source.id} className="flex mb-1 gap-1">
            <Tile 
              onClick={() => handleChangeActivePos(source.source_name, asset, isUnified ? activeTimeframe : timeframe)}
              className={`w-1/3 ${source.isActive ? "bg-green-500" : "bg-orange-500"} border-opacity-0 shadow-lg
              ${source.source_name === "cross" ? "rounded-tl-3xl" :
                source.source_name === "green" ? "rounded-bl-3xl" : ""
              } text-center py-2 cursor-pointer`}
            />
            <Tile className="w-1/3 bg-white text-black text-center py-2 uppercase">
              {shortenSourceName(source.source_name)}
            </Tile>
            <Tile 
              onClick={() => handleToggleSide(source.source_name, asset, isUnified ? activeTimeframe : timeframe)}
              className={`w-1/3 text-center border-opacity-0 shadow-lg py-2 cursor-pointer
                ${(() => {
                  try {
                    return source.side.toUpperCase() === 'BUY' ? 'bg-green-500' : 'bg-red-500';
                  } catch (e) {
                    return "bg-black";
                  }
                })()} 
                ${source.source_name === "cross" ? "rounded-tr-3xl" :
                  source.source_name === "green" ? "rounded-br-3xl" : ""
                }
              `}
            />   
          </div>
        ))}
        
        {/* {strategy === 'gyrox-v2' && 
          <div className='flex mb-1 gap-1'>
            <div><div/>
          <div/>
        } */}
        {strategy === 'gyrox-v2' && (isUnified ? activeTimeframe : timeframe) !== 'ZERO' &&
        gyroxArray
        .map(family => (
          <div className="flex mb-1 gap-1">
            <Tile className={`w-1/4 bg-white text-black text-center py-2 uppercase ${family[0].source_name[0].toUpperCase() === "X" ? "rounded-tl-3xl" :
                                                                                         family[0].source_name[0].toUpperCase() === "G" ? "rounded-bl-3xl" : ""}`}>
              {family[0].source_name[0].toUpperCase()}
            </Tile>
            {family.map(sub_source => (
              <Tile 
                onContextMenu={(event) => { event.preventDefault(); handleChangeActivePos(sub_source.source_name, asset, isUnified ? activeTimeframe : timeframe)}}
                onClick={() => handleToggleSide(sub_source.source_name, asset, isUnified ? activeTimeframe : timeframe)}
                className={`w-1/4 text-center border-opacity-0 shadow-lg py-2 cursor-pointer
                  ${(() => {
                    try {
                      let style = ''
                      if (sub_source.side.toUpperCase() === 'BUY') {
                        if (sub_source.isActive) {
                          style += ' bg-green-600 ';
                        } else {
                          style += ' bg-green-500 bg-opacity-65 ';
                        }
                        if ([statuses.upper_bound, statuses.lower_bound].includes(sub_source.sensitivity)) {
                          style += ' outline outline-orange-400 border-opacity-100 border-1 border-orange-400 outline-1 outline-offset-0 '
                        }
                      } else {
                        if (sub_source.isActive) {
                          style += ' bg-red-500 ';
                        } else {
                          style += ' bg-red-500 bg-opacity-65 ';
                        }
                        if ([statuses.upper_bound, statuses.lower_bound].includes(sub_source.sensitivity)) {
                          style += ' outline outline-cyan-400 border-opacity-100 border-1 border-cyan-400 outline-1 outline-offset-0 '
                        }
                      }
                      
                      // if (sub_source.isActive) {
                      //   style += ' outline outline-orange-400 border-opacity-100 border-1 border-orange-400 outline-3 outline-offset-0';
                      // }
                      return style;
                    } catch (e) {
                      return "bg-black";
                    }
                  })()} 
                  ${sub_source.source_name === "x3" ? "rounded-tr-3xl" :
                    sub_source.source_name === "g3" ? "rounded-br-3xl" : ""
                  }
                `}
              />)   
            )}
          </div>
        ))}
        {strategy === 'gyrox-v2' && window.screen.width <= 450 && (
            <div class="relative inline-block text-left mt-2">
              <div onClick={() => setIsActiveMenuOpen(!isActiveMenuOpen)}>
                <button type="button" class="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                  Set active
                  <svg className="-mr-1 size-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon">
                    <path fill-rule="evenodd" d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z" clip-rule="evenodd" />
                  </svg>
                </button>
              </div>
            {isActiveMenuOpen && (
              <div className="absolute left-0 z-10 mt-1 w-56 origin-top-left divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                {gyroxArray.map(family => (
                    <div className="py-1">
                      {family.map(sub_source => (
                        <div className="block px-4 py-2 text-sm text-gray-700" onClick={() => { setIsActiveMenuOpen(!isActiveMenuOpen); handleChangeActivePos(sub_source.source_name, asset, isUnified ? activeTimeframe : timeframe) }}>{sub_source.source_name}</div>
                      ))}
                    </div>
                  ))
                }
              </div>
            )}
            </div>
          )}
      </div>
      </div>
      
  );
};

export default ControlPanel;