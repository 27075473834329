import React, { useState, useEffect } from 'react';
import UserInfo from '../components/UserProfile/UserInfo';
import BrokerLinking from '../components/UserProfile/BrokerLinking';
import Modal from '../components/common/Modal';
import X from '../components/common/X';
import { fetchUserProfile, fetchSupportedBrokers } from '../services/api';
import { linkBroker, unlinkBroker } from '../services/api';
import { truncateText } from '../utils/helpers';

function UserProfilePage() {
  const [userProfile, setUserProfile] = useState(null);
  const [supportedBrokers, setSupportedBrokers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState('');
  const [broker, setBroker] = useState('');
  const [account, setAccount] = useState('');
  const [password, setPassword] = useState('');
  const [apiKey, setApiKey] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const result = await linkBroker(broker, account, password, apiKey);
      if (result) {
        setIsModalOpen(false);
        window.location.reload();
      } else {
        setError('Something went wrong..');
      }
    } catch (err) {
      setError('Something went wrong..');
    }
  };

  const handleUnlink = async (_id) => {
    try {
      const result = await unlinkBroker(_id);
      if (result) {
        setIsModalOpen(false);
        window.location.reload();
      } else {
        setError('Something went wrong..');
      }
    } catch (err) {
      setError('Something went wrong..');
    }
  };

  useEffect(() => {
    const loadUserProfile = async () => {
      const profile = await fetchUserProfile();
      setUserProfile(profile);
    };

    const loadSupportedBrokers = async () => {
      const brokers = await fetchSupportedBrokers();
      setSupportedBrokers(brokers);
    };

    loadUserProfile();
    loadSupportedBrokers();
  }, []);

  if (!userProfile) {
    return <div>Loading...</div>;
  }

  return (
    <div className="max-w-3xl mx-auto">
      <h1 className="text-3xl font-bold mb-8">Profile</h1>
      <div className="space-y-8">
        <UserInfo user={userProfile} />
        <BrokerLinking supportedBrokers={supportedBrokers} linkedBrokers={userProfile.linkedBrokers} onLinkBroker={(broker) => { setIsModalOpen(true); setBroker(broker); }} />
      </div>
      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <div className="text-center w-56">
          <div className="mx-auto my-4 w-48">
            {userProfile.linkedBrokers
            .filter((entry) => {if (entry.broker === broker) return true; else return false; })
            .map((entry) => (
              <div className="bg-white shadow-lg rounded-lg overflow-hidden my-1 max-h-12">
                <ul className="divide-y divide-gray-200">
                  <li className="p-3 flex justify-between items-center user-card">
                    <div className="flex items-center">
                      <span className="ml-3 font-medium">{truncateText(entry.account, 10)}</span>
                    </div>
                    <div>
                      <button className="text-gray-500 hover:text-gray-700" onClick={() => handleUnlink(entry.record_id)}>
                        <X/>
                        {/* <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16m-7 6h7" />
                        </svg> */}
                      </button>
                    </div>
                  </li>
                </ul>
              </div>
            ))}
              

            <h3 className="text-lg font-black text-gray-800">Enter your brokers credentials</h3>
            {error && <p className="text-red-500 mb-4">{error}</p>}
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label htmlFor='account' className='block mb-1'>{broker === `ib` ? "Account number" : "Account secret"}</label>
                <input
                  type='text'
                  id='account'
                  value={account}
                  onChange={(e) => setAccount(e.target.value)}
                  className="w-full px-3 py-2 border rounded-md"
                  required
                />
              </div>
              {broker === "okx" && (
                <>
                  <div>
                    <label htmlFor='password' className='block mb-1'>Account password</label>
                    <input
                      type='password'
                      id='password'
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className="w-full px-3 py-2 border rounded-md"
                      required
                    />
                  </div>
                  <div>
                    <label htmlFor='apiKey' className='block mb-1'>Account API key</label>
                    <input
                      type='text'
                      id='apiKey'
                      value={apiKey}
                      onChange={(e) => setApiKey(e.target.value)}
                      className="w-full px-3 py-2 border rounded-md"
                    />
                  </div>
              </>
              )

              }
              
              <button type="submit" className="w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600">
                Submit
              </button>
            </form>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default UserProfilePage;