// UserInfo.js
import React, { useState } from 'react';
import { updateApikey } from '../../services/auth'; 
import Modal from '../common/Modal';

function UserInfo({ user }) {
  const [apiKey, setApiKey] = useState(user.api_key);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState(null);

  const handleRegenerate = async () => {
    setLoading(true);
    setError(null);
    try {
      const newApiKey = await updateApikey(user.name, user.email);

      if (newApiKey) {
        setApiKey(newApiKey);
      } else {
        setError('Не удалось обновить API ключ');
      }
    } catch (err) {
      setError(err.message);
    }
    setLoading(false);
  };

  const confirmRefreshToken = () => {
    handleRegenerate();
    setIsModalOpen(false);
  }

  return (
    <div className="bg-white border-2 border-accent rounded-lg p-6 shadow-md">
      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <div className='text-center w-56'>
          <div className="mx-auto my-4 w-48">
            <h3 className="text-lg font-black text-gray-800 mb-2">Confirm API token refresh</h3>
            <p className='text-sm font-black text-gray-800 mb-4'>This action is irreversible</p>
            <button onClick={() => confirmRefreshToken()} className="w-full bg-red-500 text-white py-2 rounded-md hover:bg-red-600">
                Confirm
            </button>
          </div>
        </div>
      </Modal>
      <h2 className="text-2xl font-bold mb-4 text-gray-800">User Information</h2>
      <div className="space-y-2">
        <p>
          <span className="font-semibold text-gray-700">Name:</span> {user.name}
        </p>
        <p>
          <span className="font-semibold text-gray-700">Email:</span> {user.email}
        </p>
        <p>
          <span className="font-semibold text-gray-700">API token:</span> {apiKey}
        </p>
        <button
          onClick={() => setIsModalOpen(true)}
          disabled={loading}
          className="mt-2 px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
        >
          {loading ? 'Обновление...' : 'Refresh API token'}
        </button>
        {error && <p className="text-red-500">{error}</p>}
      </div>
    </div>
  );
}

export default UserInfo;