import React from 'react';
import Tile from '../common/Tile';
import logos from '../common/Logos';
import { truncateText } from '../../utils/helpers';

function AccountList({ accounts, onSelect }) {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
      {accounts.map((account) => (
        <Tile
          key={account.id}
          onClick={() => onSelect(account)}
          className="flex items-center p-4 cursor-pointer"
        >
          <img
            src={logos[account.broker]}
            alt={`${account.broker} logo`}
            className="w-12 h-12 mr-4"
          />
          <div>
            <h3 className="font-semibold text-gray-800">{account.broker.toUpperCase()}</h3>
            <p className="text-sm text-gray-600">{truncateText(account.account, 12)}</p>
          </div>
        </Tile>
      ))}
    </div>
  );
}

export default AccountList;