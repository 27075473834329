// import React, { useState } from 'react';
import Tile from '../common/Tile';
// import { fetchDeepAssets } from '../../services/api';

function AssetList({ assets, onSelect, detailed, handleDeepFetch, isFetching }) {
  // const [isFetching, setIsFetching] = useState(false);

  if (detailed === undefined) {
    detailed = false;
  }

  return (
    <div className="grid grid-cols-2 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6 gap-3">
      {assets
      .filter((entry) => {return entry.isAssetActive})
      .map((asset) => (
        <Tile
          key={asset.id}
          onClick={() => onSelect(asset)}
          className="flex cursor-pointer truncate h-12 items-center"
        >
          {asset.broker === 'okx' && (
            <img
              src={"https://s3-symbol-logo.tradingview.com/crypto/XTVC" +
                asset.symbol.substring(
                0,
                asset.symbol.indexOf('/')
              ) + ".svg"}
              alt="Asset logo"
              className='w-12 h-12'
            />
          )}
          
          {detailed ? (
            <div>
              <h3 className="font-semibold lg:text-lg md:text-md sm:text-sm text-gray-800">{asset.symbol}</h3>
              <p className="text-lg text-gray-700">{asset.quantity}</p>
              <p className="text-sm text-gray-600">{asset.side.toUpperCase()}</p>
            </div>
          ) : (
            <div className="m-auto">
              <p className="font-semibold align-middle text-sm lg:text-md md:text-md sm:text-sm text-gray-800 text-center">{asset.broker === 'okx' ? asset.symbol.substring(
                    0,
                    asset.symbol.indexOf('/')
                  ) : asset.symbol}</p>
            </div>
          )}
          
        </Tile>
      ))}
    </div>
  );
}

export default AssetList;