import React, { useState } from 'react'

function ToggleSwitch({ onToggle, OptionTextOFF, OptionTextON, defaultOpt }) {
  if (defaultOpt === undefined) {
    defaultOpt = "left";
  }
  const [isLeftActive, setIsLeftActive] = useState(defaultOpt === 'left' ? true : false);
  const [isRightActive, setIsRightActive] = useState(defaultOpt === 'right' ? true : false);
  const handleToggleClick = (side) => {
    switch (side) {
      case "left":
        setIsLeftActive(true);
        setIsRightActive(false);
        onToggle(true);
        break;
      case "right":
        setIsLeftActive(false);
        setIsRightActive(true);
        onToggle(false);
        break;
      default:
        break;
    }
  };


  return (
    <div class="bg-gray-200 text-sm text-gray-500 leading-none border-2 border-gray-200 rounded-full inline-flex mr-4">
        <button className={`inline-flex items-center transition-colors duration-300 slide hover:text-blue-400 rounded-full px-4 py-2
            ${isLeftActive ? "bg-white rounded-full accent-teal-600 text-blue-400 shadow-md" : ""}`} id="grid"
            onClick={() => handleToggleClick("left")}
            >
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="fill-current w-4 h-4 mr-2"><rect x="3" y="3" width="7" height="7"></rect><rect x="14" y="3" width="7" height="7"></rect><rect x="14" y="14" width="7" height="7"></rect><rect x="3" y="14" width="7" height="7"></rect></svg>
          <span>{OptionTextOFF}</span>
        </button>
        <button className={`inline-flex items-center transition-colors duration-300 slide hover:text-blue-400 rounded-full px-4 py-2
            ${isRightActive ? "bg-white rounded-full accent-teal-600 text-blue-400 shadow-md" : ""}`} id="list"
            onClick={() => handleToggleClick("right")}
          >
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="fill-current w-4 h-4 mr-2"><line x1="8" y1="6" x2="21" y2="6"></line><line x1="8" y1="12" x2="21" y2="12"></line><line x1="8" y1="18" x2="21" y2="18"></line><line x1="3" y1="6" x2="3.01" y2="6"></line><line x1="3" y1="12" x2="3.01" y2="12"></line><line x1="3" y1="18" x2="3.01" y2="18"></line></svg>
          <span>{OptionTextON}</span>
        </button>
    </div>
  );
};

export default ToggleSwitch;
