import React, { useEffect, useRef } from 'react';

function TradingViewWidget({ tvSymbol }) {
  const container = useRef(null);

  useEffect(() => {
    if (!container.current) return;

    const script = document.createElement('script');
    script.src = 'https://s3.tradingview.com/tv.js'; // Добавляем ссылку на скрипт TradingView
    script.async = true;
    script.onload = () => {
      new window.TradingView.widget({
        symbol: tvSymbol,
        autosize: true,
        interval: 'D',
        timezone: 'Etc/UTC',
        theme: 'light',
        style: '8',
        locale: 'en',
        container_id: 'tradingview_widget',
      });
    };
    container.current.appendChild(script);
  }, []);

  return (
    <div className="tradingview-widget-container" ref={container}>
      <div id="tradingview_widget" style={{ height: '500px', width: '100%' }}></div>
    </div>
  );
}

export default TradingViewWidget;
