import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 60000
});

//// EXPERIMENTAL SECTION ////
//// EXPERIMENTAL SECTION ////
//// EXPERIMENTAL SECTION ////

export const fetchAccountsExp = async () => {
  const response = await axios.get('/user/exp/accounts');
  return response.data;
}

export const fetchStrategiesExp = async () => {
  const response = await axios.get('/user/exp/strategies');
  return response.data;
}

//// EXPERIMENTAL SECTION ////
//// EXPERIMENTAL SECTION ////
//// EXPERIMENTAL SECTION ////


export const getAuthToken = () => {
  if (api.defaults.headers.common['Authorization'] !== undefined) {
    return api.defaults.headers.common['Authorization'];
  }
}



export const setAuthToken = (token) => {
  if (token) {
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete api.defaults.headers.common['Authorization'];
  }
};

export const fetchAccounts = async () => {
  const response = await api.get('/user/accounts');
  return response.data;
};

export const fetchAssets = async (account, broker) => {
    const response = await api.get(
      `/user/fetch-assets`,
      {
        params: {
          account,
          broker
        }
      }
    );
  return response.data;
};

export const fetchUserProfile = async () => {
  const response = await api.get('/user/profile');
  return response.data;
};

export const fetchSupportedBrokers = async () => {
  const response = await api.get('/brokers/supported');
  return response.data;
};

export const linkBroker = async (broker, account, password, apiKey = null) => {
  const response = await api.post(`/brokers/link`, {
                                                      broker: broker,
                                                      account: account,
                                                      password: password,
                                                      api_key: apiKey
                                                    });
  if (response.status !== 200) {
    return false;
  }
  return true;
};


export const unlinkBroker = async (_id) => {
  const response = await api.delete(
    `/brokers/unlink`,
    {
      params: {
        _id
      }                                                  
    });
  if (response.status !== 200) {
    return false;
  }
  return true;
};


export const fetchMasterStatus = async (broker) => {
  const response = await api.get(
    `/brokers/master-status`,
    {
      params: {
        broker
      }
    }
  );
  return response.data;
};


export const fetchStatistics = async (broker, account, symbol, sectype) => {
  const response = await api.get(
    '/user/fetch-statistics',
    {
      params: {
        broker, account, symbol, sectype
      }
    }
  );
  return response.data;
}

export const fetchSources = async (broker, account, symbol, sectype) => {
  const response = await api.get(
    '/user/fetch-sources',
    {
      params: {
        broker, account, symbol, sectype
      }
    }
  );
  return response.data;
}

export default api;


export const changeActiveSource = async (sourceName, symbol, dest, account = null, sectype = null) => {
  try {
    let url, data;
    switch (dest) {
      case 'okx':
        url = '/user/change-active-source-crypto';
        data = { symbol, broker: dest, name: sourceName, account };
        break;
      case 'ib':
      case 'ig':
        url = '/user/change-active-source-sec';
        data = { symbol, broker: dest, sectype, name: sourceName, account };
        break;
      default:
        throw new Error(`Unsupported broker: ${dest}`);
    }

    const response = await api.put(url, data);
    return response.data.now_active;
  } catch (error) {
    console.error(`Exception: Cannot connect to DBCLIENT backend: ${error}`);
    if (error.response && error.response.data) {
      console.error('Server response:', error.response.data);
    }
    return null;
  }
};


export const toggleSide = async (sourceName, symbol, dest, account = null, sectype = null) => {
  try {
    let url, data;
    switch (dest) {
      case 'ib':
        url = '/user/toggle-side-sec';
        data = {name: sourceName, symbol, broker: dest, sectype, account };
        break;
      case 'ig':
        url = '/user/toggle-side-sec';
        data = { epic: symbol, broker: dest, sectype, account };
        break;
      case 'okx':
        url = '/user/toggle-side-crypto';
        data = { name: sourceName, symbol, broker: dest, account};
        break;
      default:
        throw new Error(`Unsupported broker: ${dest}`);
    }
    const response = await api.put(url, data, { timeout: 10000 });
    return response.data.new_status;
  } catch (error) {
    console.error(`Exception: Cannot connect to DBCLIENT: ${error}`);
    return null;
  }
};


export const toggleAngleBlock = async (symbol, dest, account = null, sectype) => {
  try{
    let url, data;
    switch (dest) {
      case 'okx':
        url = '/user/toggle-angleblock-crypto';
        data = {symbol, broker: dest};
        break;
      case 'ib':
        url = '/user/toggle-angleblock-sec';
        data = {symbol, broker: dest, sectype, account};
        break;
      case 'ig':
        url = '/user/toggle-angleblock-sec';
        data = {epic: symbol, broker: dest};
        break;
      default:
        throw new Error(`Unsupported broker: ${dest}`);
    }
    const response = await api.put(url, data)
    return response.data.new_status;
  }catch (error){
    console.error(`Exception: Cannot connect to DBCLIENT: ${error}`);
    return null;
  }
}

export const toggleMaster = async (symbol, dest, account = null, sectype = '') => {
  try {
    let url, data;
    switch (dest) {
      case 'okx':
        url = '/user/toggle-crypto-algo';
        data = { symbol, broker: dest };
        break;
      case 'ib':
        url = '/user/toggle-sec-algo';
        data = { symbol, broker: dest, sectype, account };
        break;
      case 'ig':
        url = '/user/toggle-sec-algo';
        data = { epic: symbol, broker: dest };
        break;
      default:
        throw new Error(`Unsupported broker: ${dest}`);
    }

    const response = await api.put(url, data);
    return response.data.new_status;
  } catch (error) {
    console.error(`Exception: Cannot connect to DB_CLIENT: ${error}`);
    return null;
  }
};

export const toggleFestival = async (symbol, dest, account = null, sectype = '') => {
  try {
    let url, data 

    switch (dest) {
      case 'okx':
        url = '/user/toggle-festival-crypto';
        data = {
          symbol: symbol,
          broker: dest,
        };
        break;
      case 'ib':
        url = '/user/toggle-festival-sec';
        data = {
          symbol: symbol,
          broker: dest,
          sectype: sectype,
          account: account,
        };
        break;
      case 'ig':
        url = '/user/toggle-festival-sec';
        data = {
          epic: symbol,
          broker: dest,
        };
        break;
      default:
        console.error(`Unsupported broker: ${dest}`);
        return null;
    }

    const response = await api.put(url, data);
    return response.data.new_status;
  } catch (error) {
    console.error(`Error toggling festival for symbol: ${symbol}, broker: ${dest}`, error);
    return null; 
  }
};




export const toggleOffensive = async (symbol, dest, account = null, sectype = '') => {
  try {
    let url, data 

    switch (dest) {
      case 'okx':
        url = '/user/toggle-offensive-crypto';
        data = {
          symbol: symbol,
          broker: dest,
        };
        break;
      case 'ib':
        url = '/user/toggle-offensive-sec';
        data = {
          symbol: symbol,
          broker: dest,
          sectype: sectype,
          account: account,
        };
        break;
      case 'ig':
        url = '/user/toggle-offensive-sec';
        data = {
          epic: symbol,
          broker: dest,
        };
        break;
      default:
        console.error(`Unsupported broker: ${dest}`);
        return null;
    }

    const response = await api.put(url, data);
    return response.data.new_status;
  } catch (error) {
    console.error(`Error toggling offensive for symbol: ${symbol}, broker: ${dest}`, error);
    return null; 
  }
};


export const switchMaster = async (dest) => {
  let url, data

  try {
    switch (dest) {
      case 'okx':
        url = '/user/toggle-broker';
        data = { name: dest };
        break;
      case 'ib':
        url = '/user/toggle-broker';
        data = { name: dest };
        break;
      default:
        console.error(`Unsupported broker: ${dest}`);
        return null;
    }

    const response = await api.put(url, data, { timeout: 10000 });
    return response.data.new_status; 
  } catch (error) {
    console.error(`Error switching master for broker: ${dest}`, error);
    return null; 
  }
};


export const toggleActive = async (symbol, broker, account = 'default', sectype = '') => {
  try {
    const data = { broker };
    if (broker === 'ig') {
      data.epic = symbol;
    } else {
      data.symbol = symbol;
    }
    data.account = account;
    if (sectype) data.sectype = sectype;
    const response = await api.put('/user/toggle-active', data);
    return response.data.new_status;
  } catch (error) {
    console.error('Error toggling active status:', error);
    return null;
  }
};

export const fetchDeepAssets = async (broker, account) => {
  try {
    const params = { broker , account};
    const response = await api.get('/user/deep-fetch-assets', {
      params,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching assets:", error);
    throw error; 
  }
};
