import React, { useState, useEffect } from 'react';

const ConfigPanelForColors = ({ handleSetTimeframeSideClick, timeframe }) => {
  const [activeButton, setActiveButton] = useState(null);
  const [size, setSize] = useState({ width: 40, height: 20 });
  const screenWidthafter = window.innerWidth;
  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;

      if (screenWidth < 640) {
        const newWidth = Math.max(2, (screenWidth+(screenWidthafter)) * (0.00005*(screenWidth-100)));
        const newHeight = Math.max(5, (screenWidth+screenWidthafter) * 0.015);
        setSize({ width: newWidth, height: newHeight });
      } else {
        const newWidth = Math.max(2, (screenWidth) * 0.02);
        const newHeight = Math.max(5, (screenWidth) * 0.015);
        setSize({ width: newWidth, height: newHeight });
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleClick = (side) => {
    setActiveButton(side);
    handleSetTimeframeSideClick(side, timeframe);
    setTimeout(() => setActiveButton(null), 150);
  };

  return (
    <div className="pt-4">
      <table className="w-full"></table>

      <div className="flex space-x-2">
        <button
          onClick={() => handleClick('buy')}
          style={{ width: `${size.width}px`, height: `${size.height}px` }}
          className={`rounded-md shadow-md transition-all transform hover:scale-105 active:scale-95 ${
            activeButton === 'buy' ? 'bg-transparent' : 'bg-green-500'
          }`}
        ></button>

        <button
          onClick={() => handleClick('sell')}
          style={{ width: `${size.width}px`, height: `${size.height}px` }}
          className={`rounded-md shadow-md transition-all transform hover:scale-105 active:scale-95 ${
            activeButton === 'sell' ? 'bg-transparent' : 'bg-red-500'
          }`}
        ></button>
      </div>
    </div>
  );
};

export default ConfigPanelForColors;
