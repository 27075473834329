import React from 'react';

function Tile({ children, onClick, className, onContextMenu }) {
  return (
    <div
      onClick={onClick}
      onContextMenu={onContextMenu}
      className={`border-2 border-accent rounded-lg shadow-md transition duration-300 ease-in-out ${
        onClick ? 'hover:shadow-lg hover:border-accent-dark' : ''
      } ${className}`}
    >
      {children}
    </div>
  );
}

export default Tile;